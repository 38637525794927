import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { UserDoc } from '../../common/collections/Users';
import UserCreateForm from './UserCreateForm';

export default function UserCreateScreen() {
  const navigate = useNavigate();
  const onComplete = useCallback(
    (userRef: DocumentReference<UserDoc>) => {
      navigate(`../${userRef.id}`, { replace: true });
    },
    [navigate],
  );

  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'UserCreateScreen' });

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('users.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('users.create.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {t('title')}
                </Heading>
              </HStack>

            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <UserCreateForm onComplete={onComplete} />
      </GridItem>
    </Grid>
  );
}
