import { Link, Wrap } from '@chakra-ui/react';
import { DocumentReference, query, where } from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useContractsCollectionRef } from '../../common/collections/Contracts';
import { UnitDoc } from '../../common/collections/Units';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type Props = {
  unitRef: DocumentReference<UnitDoc>;
};

export default function ContractCell({ unitRef }: Props) {
  const customerSnap = useCustomerSnap();

  const contractsCollectionRef = useContractsCollectionRef(customerSnap.ref);
  const { data: contractsSnap } = useFirestoreCollection(query(
    contractsCollectionRef,
    where('unitRef', '==', unitRef),
  ));

  return (
    <Wrap>
      {contractsSnap.docs.map((contractSnap) => (
        <Link as={RouterLink} key={contractSnap.id} to={`../contracts/${contractSnap.id}`}>
          {contractSnap.data().name}
        </Link>
      ))}
    </Wrap>
  );
}
