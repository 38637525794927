import {
  Button,
  Card,
  CardHeader,
  Center,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useAnnouncementsCollectionRef } from '../../common/collections/Announcements';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import AnnouncementRow from './AnnouncementRow';

export default function AnnouncementsTable() {
  const customerSnap = useCustomerSnap();
  const propertySnap = usePropertySnap();

  const announcementsCollectionRef = useAnnouncementsCollectionRef(customerSnap.ref);
  const { data: announcementsSnap } = useFirestoreCollection(
    query(
      announcementsCollectionRef,
      where('propertyRef', '==', propertySnap.ref),
      orderBy('createdAt', 'desc'),
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'AnnouncementsScreen' });

  if (!announcementsSnap.docs.length) {
    return (
      <Center h="100%">
        <Button as={Link} leftIcon={<Icon as={LuPlusSquare} />} to="new">
          {t('createButton.default')}
        </Button>
      </Center>
    );
  }

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.message')}</Th>
              <Th isNumeric>{t('tableHeader.createdAt')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {announcementsSnap.docs.map((announcementSnap) => (
              <AnnouncementRow announcementSnap={announcementSnap} key={announcementSnap.id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
