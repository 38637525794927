import { Heading } from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useFirestoreCollection, useFirestoreDocData } from 'reactfire';

import { usePropertiesCollectionRef } from '../../common/collections/Properties';
import { UserRole } from '../../common/collections/Users';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { useCurrentUserRef } from '../CheckAuthLayout';

export default function PropertyRedirectScreen() {
  const { t } = useTranslation('translations', { keyPrefix: 'PropertyRedirectScreen' });

  const currentUserRef = useCurrentUserRef();
  const { data: currentUserDoc } = useFirestoreDocData(currentUserRef);

  const customerSnap = useCustomerSnap();
  const propertiesCollectionRef = usePropertiesCollectionRef(customerSnap.ref);
  const propertiesQuery = useMemo(
    () => {
      if (currentUserDoc.role === UserRole.ADMIN) {
        return query(
          propertiesCollectionRef,
          limit(1),
        );
      }

      return query(
        propertiesCollectionRef,
        where('managerRefs', 'array-contains', currentUserRef),
        limit(1),
      );
    },
    [currentUserDoc.role, propertiesCollectionRef, currentUserRef],
  );
  const { data: propertiesSnap } = useFirestoreCollection(propertiesQuery);

  if (propertiesSnap.docs.length) {
    return (
      <Navigate replace to={propertiesSnap.docs[0].id} />
    );
  }

  return (
    <Heading>
      {t('title')}
    </Heading>
  );
}
