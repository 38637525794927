import {
  Badge,
  BadgeProps,
  HStack,
  Icon,
  IconButton,
  Link,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { InvoiceDoc, InvoiceStatus } from '../../common/collections/Invoices';

export type Props = {
  invoiceSnap: QueryDocumentSnapshot<InvoiceDoc>;
};

export default function InvoiceRow({ invoiceSnap }: Props) {
  const invoiceDoc = useMemo(() => invoiceSnap.data(), [invoiceSnap]);

  const { data: contractDoc } = useFirestoreDocData(invoiceDoc.contractRef);

  const { data: tenantDoc } = useFirestoreDocData(contractDoc.tenantRef);

  const { t } = useTranslation('translations', { keyPrefix: 'UnitScreen.InvoiceRow' });

  const statusColorScheme = useMemo<BadgeProps['colorScheme']>(
    () => {
      switch (invoiceDoc.status) {
        case InvoiceStatus.DRAFT: {
          return 'gray';
        }
        case InvoiceStatus.PENDING:
        case InvoiceStatus.SENT: {
          return 'yellow';
        }
        case InvoiceStatus.WRITTEN_OFF:
        case InvoiceStatus.OVERDUE:
        case InvoiceStatus.CANCELLED:
        case InvoiceStatus.DISPUTED:
        case InvoiceStatus.FAILED: {
          return 'red';
        }
        case InvoiceStatus.REFUNDED: {
          return 'orange';
        }
        case InvoiceStatus.PAID: {
          return 'green';
        }
        default: {
          return 'teal';
        }
      }
    },
    [invoiceDoc.status],
  );

  return (
    <Tr>
      <Td>
        <Link as={RouterLink} to={`../../contracts/${invoiceDoc.contractRef.id}`}>
          {contractDoc.name}
        </Link>
      </Td>

      <Td>
        <Link as={RouterLink} to={`../../tenants/${invoiceDoc.tenantRef.id}`}>
          {tenantDoc.firstName}
          {' '}
          {tenantDoc.lastName}
        </Link>
      </Td>

      <Td opacity={invoiceDoc.periodAmount === 0 ? 0.5 : 1}>
        {t('periodAmount', {
          formatParams: {
            periodAmount: { currency: contractDoc.currency },
          },
          periodAmount: invoiceDoc.periodAmount / 100,
        })}
      </Td>

      <Td opacity={invoiceDoc.overduePenaltyAmount === 0 ? 0.5 : 1}>
        {t('overduePenaltyAmount', {
          formatParams: {
            overduePenaltyAmount: { currency: contractDoc.currency },
          },
          overduePenaltyAmount: invoiceDoc.overduePenaltyAmount / 100,
        })}
      </Td>

      <Td opacity={invoiceDoc.balanceCorrectionAmount === 0 ? 0.5 : 1}>
        {t('balanceCorrectionAmount', {
          balanceCorrectionAmount: invoiceDoc.balanceCorrectionAmount / 100,
          formatParams: {
            balanceCorrectionAmount: { currency: contractDoc.currency },
          },
        })}
      </Td>

      <Td>
        {t('period', {
          endDate: invoiceDoc.endsAt.toDate(),
          startDate: invoiceDoc.startsAt.toDate(),
        })}
      </Td>

      <Td>
        <Badge colorScheme={statusColorScheme}>
          {invoiceDoc.status}
        </Badge>
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={`../../invoices/${invoiceSnap.id}`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
