import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import { doc, DocumentReference } from 'firebase/firestore';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { TelegramBotDoc, useTelegramBotsCollectionRef } from '../common/collections/TelegramBots';
import useShowError from '../hooks/useShowError';
import { useCustomerSnap } from './CustomerSnapProvider';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
}

export default function TelegramBotFormControl({
  isRequired = false,
  label,
  name,
}: Props) {
  const showError = useShowError();
  const customerSnap = useCustomerSnap();
  const telegramBotsCollectionRef = useTelegramBotsCollectionRef(customerSnap.ref);

  const { data: telegramBotsCollectionSnap } = useFirestoreCollection(telegramBotsCollectionRef);

  const [input, meta, helper] = useField<DocumentReference<TelegramBotDoc> | null>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(
        e.target.value.length ? doc(telegramBotsCollectionRef, e.target.value) : null,
      )
        .catch(showError);
    },
    [helper, telegramBotsCollectionRef, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      {telegramBotsCollectionSnap.docs.length ? (
        <Select onChange={handleChange} value={input.value?.id ?? undefined}>
          <option value="">-</option>
          {telegramBotsCollectionSnap.docs.map((propertySnap) => (
            <option
              key={propertySnap.id}
              value={propertySnap.id}
            >
              {propertySnap.data().name}
            </option>
          ))}
        </Select>
      ) : (
        <Box
          alignItems="center"
          border={1}
          borderColor="inherit"
          borderRadius="md"
          borderStyle="solid"
          display="flex"
          fontSize="md"
          h={10}
          px={4}
        >
          <Text flex={1}>No bots found.</Text>
          <Button as={Link} size="xs" target="_blank" to={`/${customerSnap.id}/admin/telegramBots/new`}>Create Bot</Button>
        </Box>
      )}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
