import { Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export interface Props {
  label: string;
  name: string;
}

export default function BooleanFormControl({ label, name }: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<boolean>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      helper.setValue(e.target.checked)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <Checkbox isChecked={input.value} onChange={handleChange}>
        {label}
      </Checkbox>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
