import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useUnitsCollectionRef } from '../../common/collections/Units';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import UnitSnapProvider from '../../components/UnitSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function UnitSnapLayout({ fallback }: Props) {
  const { unitId } = useParams<{ unitId: string }>();

  const customerSnap = useCustomerSnap();

  const unitsCollectionRef = useUnitsCollectionRef(customerSnap.ref);
  const unitRef = useMemo(
    () => doc(unitsCollectionRef, unitId),
    [unitId, unitsCollectionRef],
  );

  const { data: unitSnap } = useFirestoreDoc(unitRef);

  if (!unitSnap.exists()) {
    return fallback;
  }

  return (
    <UnitSnapProvider snap={unitSnap}>
      <Outlet />
    </UnitSnapProvider>
  );
}
