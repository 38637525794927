import { Button, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TelegramBotDoc } from '../../common/collections/TelegramBots';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (telegramBotRef: DocumentReference<TelegramBotDoc>) => void;
  telegramBotSnap: QueryDocumentSnapshot<TelegramBotDoc>;
};

export default function TelegramBotUpdateForm({
  onComplete,
  telegramBotSnap,
}: Props) {
  const telegramBotDoc = useMemo(() => telegramBotSnap.data(), [telegramBotSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'TelegramBotUpdateScreen.TelegramBotUpdateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      shortDescription: yup
        .string()
        .label(t('shortDescription.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      description: telegramBotDoc.description,
      name: telegramBotDoc.name,
      shortDescription: telegramBotDoc.shortDescription,
    }),
    [telegramBotDoc],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        await setDoc(
          telegramBotSnap.ref,
          {
            description: values.description,
            name: values.name,
            shortDescription: values.shortDescription,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );

        onComplete(telegramBotSnap.ref);
      } catch (err) {
        showError(err);
      }
    },
    [telegramBotSnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextFormControl
            isRequired
            label={t('name.label')}
            name="name"
          />

          <TextareaFormControl
            isRequired
            label={t('shortDescription.label')}
            name="shortDescription"
          />

          <TextareaFormControl
            isRequired
            label={t('description.label')}
            name="description"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            type="submit"
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
