import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { ContractDoc } from './Contracts';
import { CustomerDoc } from './Customers';
import { PropertyDoc } from './Properties';
import { TenantDoc } from './Tenants';

export type FineDoc = {
  _v: 1;
  amount: number;
  comment: string;
  contractRef: DocumentReference<ContractDoc>;
  createdAt: Timestamp;
  name: string;
  propertyRef: DocumentReference<PropertyDoc>;
  tenantRef: DocumentReference<TenantDoc>;
  updatedAt: Timestamp;
};

export const isFineDoc = (
  doc?: DocumentData,
): doc is FineDoc => true;

export const isFineRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<FineDoc> => ref.parent.id === 'fines' && ref.parent.parent?.parent?.id === 'customers';

function isFineSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
FineDoc
>;
function isFineSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
FineDoc
>;
function isFineSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
FineDoc
> | QueryDocumentSnapshot<
FineDoc
> {
  return isFineRef(snap.ref);
}

export { isFineSnap };

export const getFinesCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'fines') as CollectionReference<FineDoc>;

export const useFinesCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getFinesCollectionRef(customerRef), [customerRef]);
