import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { UnitDoc } from '../common/collections/Units';

export type Props = {
  snap: QueryDocumentSnapshot<UnitDoc>;
};

const Context = createContext<null | QueryDocumentSnapshot<UnitDoc>>(null);

export const useUnitSnap = () => {
  const contractSnap = useContext(Context);

  if (!contractSnap) {
    throw new Error('UnitSnapProvider');
  }

  return contractSnap;
};

export default function UnitSnapProvider({
  children,
  snap,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={snap}>
      {children}
    </Context.Provider>
  );
}
