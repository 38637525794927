import {
  Button,
  Card,
  CardHeader,
  Center,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useUsersCollectionRef } from '../../common/collections/Users';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import UserRow from './UserRow';

export default function UsersScreen() {
  const customerSnap = useCustomerSnap();
  const usersCollectionRef = useUsersCollectionRef(customerSnap.ref);

  const { data: usersSnap } = useFirestoreCollection(
    query(
      usersCollectionRef,
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'UsersScreen' });

  if (!usersSnap.docs.length) {
    return (
      <Center h="100%">
        <Button as={Link} leftIcon={<Icon as={LuPlusSquare} />} to="new">
          {t('createButton.default')}
        </Button>
      </Center>
    );
  }

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.name')}</Th>
              <Th>{t('tableHeader.role')}</Th>
              <Th>{t('tableHeader.properties')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {usersSnap.docs.map((userSnap) => (
              <UserRow key={userSnap.id} userSnap={userSnap} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
