import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  query,
  where,
} from 'firebase/firestore';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { TelegramBotDoc } from '../common/collections/TelegramBots';
import {
  TelegramPaymentTokenDoc,
  useTelegramPaymentTokensCollectionRef,
} from '../common/collections/TelegramPaymentTokens';
import useShowError from '../hooks/useShowError';
import { useCustomerSnap } from './CustomerSnapProvider';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
  telegramBotRef: DocumentReference<TelegramBotDoc>;
}

export function TelegramPaymentTokenFormControlPlaceholder({
  label,
}: {
  label: string;
}) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <Select disabled>
        <option value="">-</option>
      </Select>
    </FormControl>
  );
}

export default function TelegramPaymentTokenFormControl({
  isRequired = false,
  label,
  name,
  telegramBotRef,
}: Props) {
  const showError = useShowError();

  const customerSnap = useCustomerSnap();
  const telegramPaymentTokensCollectionRef = useTelegramPaymentTokensCollectionRef(
    customerSnap.ref,
  );

  const {
    data: telegramPaymentTokensCollectionSnap,
  } = useFirestoreCollection(query(
    telegramPaymentTokensCollectionRef,
    where('telegramBotRef', '==', telegramBotRef),
  ));

  const [input, meta, helper] = useField<DocumentReference<TelegramPaymentTokenDoc> | null>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(
        e.target.value.length ? doc(telegramPaymentTokensCollectionRef, e.target.value) : null,
      )
        .catch(showError);
    },
    [helper, telegramPaymentTokensCollectionRef, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      {telegramPaymentTokensCollectionSnap.docs.length ? (
        <Select onChange={handleChange} value={input.value?.id ?? undefined}>
          <option value="">-</option>
          {telegramPaymentTokensCollectionSnap.docs.map((propertySnap) => (
            <option
              key={propertySnap.id}
              value={propertySnap.id}
            >
              {propertySnap.data().name}
            </option>
          ))}
        </Select>
      ) : (
        <Box
          alignItems="center"
          border={1}
          borderColor="inherit"
          borderRadius="md"
          borderStyle="solid"
          display="flex"
          fontSize="md"
          h={10}
          px={4}
        >
          <Text flex={1}>No tokens found.</Text>
          <Button as={Link} size="xs" target="_blank" to={`/${customerSnap.id}/admin/telegramBots/${telegramBotRef.id}/telegramPaymentTokens/new`}>Create Token</Button>
        </Box>
      )}

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
