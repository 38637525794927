import { Icon, MenuItem, useColorMode } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMoon, LuSun } from 'react-icons/lu';

export default function ColorMode() {
  const { colorMode, setColorMode } = useColorMode();
  const { t } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const handleClick = useCallback(
    () => {
      setColorMode(colorMode === 'light' ? 'dark' : 'light');
    },
    [colorMode, setColorMode],
  );

  return (
    <MenuItem icon={<Icon as={colorMode === 'light' ? LuMoon : LuSun} />} onClick={handleClick}>
      {t(`theme.${colorMode === 'dark' ? 'light' : 'dark'}`)}
    </MenuItem>
  );
}
