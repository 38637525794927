import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useInvoicesCollectionRef } from '../../common/collections/Invoices';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import InvoiceSnapProvider from '../../components/InvoiceSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function InvoiceSnapLayout({ fallback }: Props) {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const customerSnap = useCustomerSnap();

  const invoicesCollectionRef = useInvoicesCollectionRef(customerSnap.ref);
  const invoiceRef = useMemo(
    () => doc(invoicesCollectionRef, invoiceId),
    [invoiceId, invoicesCollectionRef],
  );

  const { data: invoiceSnap } = useFirestoreDoc(invoiceRef);

  if (!invoiceSnap.exists()) {
    return fallback;
  }

  return (
    <InvoiceSnapProvider snap={invoiceSnap}>
      <Outlet />
    </InvoiceSnapProvider>
  );
}
