import {
  Button,
  Card,
  CardHeader,
  Center,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import {
  useTelegramPaymentTokensCollectionRef,
} from '../../common/collections/TelegramPaymentTokens';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { useTelegramBotSnap } from '../../components/TelegramBotSnapProvider';
import TelegramPaymentTokenRow from './TelegramPaymentTokenRow';

export default function TelegramPaymentTokensScreen() {
  const telegramBotSnap = useTelegramBotSnap();

  const customerSnap = useCustomerSnap();
  const telegramPaymentTokensCollectionRef = useTelegramPaymentTokensCollectionRef(
    customerSnap.ref,
  );

  const { data: telegramPaymentTokensSnap } = useFirestoreCollection(
    query(
      telegramPaymentTokensCollectionRef,
      where('telegramBotRef', '==', telegramBotSnap.ref),
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'TelegramPaymentTokensScreen' });

  if (!telegramPaymentTokensSnap.docs.length) {
    return (
      <Center h="100%">
        <Button as={Link} leftIcon={<Icon as={LuPlusSquare} />} to="new">
          {t('createButton.default')}
        </Button>
      </Center>
    );
  }

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.name')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {telegramPaymentTokensSnap.docs.map((telegramPaymentTokenSnap) => (
              <TelegramPaymentTokenRow
                key={telegramPaymentTokenSnap.id}
                telegramPaymentTokenSnap={telegramPaymentTokenSnap}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
