import { DocumentData, DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import React, { ReactElement, ReactNode } from 'react';
import { useFirestoreDoc } from 'reactfire';

import SnapNotFoundError from '../common/SnapshotNotFoundError';

export type Props<T> = {
  children: (snapshot: QueryDocumentSnapshot<T>) => ReactNode;
  reference: DocumentReference<T>;
};

function DocumentLoader<T = DocumentData>({ children, reference }: Props<T>): ReactElement {
  const { data: snap } = useFirestoreDoc(reference);

  if (!snap.exists()) {
    throw new SnapNotFoundError(snap);
  }

  return (
    <>
      {children(snap)}
    </>
  );
}

export default DocumentLoader;
