import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useComplainsCollectionRef } from '../../common/collections/Complains';
import ComplainSnapProvider from '../../components/ComplainSnapProvider';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function ComplainSnapLayout({ fallback }: Props) {
  const { complainId } = useParams<{ complainId: string }>();

  const customerSnap = useCustomerSnap();

  const complainsCollectionRef = useComplainsCollectionRef(customerSnap.ref);
  const complainRef = useMemo(
    () => doc(complainsCollectionRef, complainId),
    [complainId, complainsCollectionRef],
  );

  const { data: complainSnap } = useFirestoreDoc(complainRef);

  if (!complainSnap.exists()) {
    return fallback;
  }

  return (
    <ComplainSnapProvider snap={complainSnap}>
      <Outlet />
    </ComplainSnapProvider>
  );
}
