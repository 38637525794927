import { Button, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TelegramPaymentTokenDoc } from '../../common/collections/TelegramPaymentTokens';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (telegramPaymentTokenRef: DocumentReference<TelegramPaymentTokenDoc>) => void;
  telegramPaymentTokenSnap: QueryDocumentSnapshot<TelegramPaymentTokenDoc>;
};

export default function TelegramPaymentTokenUpdateForm({
  onComplete,
  telegramPaymentTokenSnap,
}: Props) {
  const telegramPaymentTokenDoc = useMemo(
    () => telegramPaymentTokenSnap.data(),
    [telegramPaymentTokenSnap],
  );

  const { t } = useTranslation('translations', { keyPrefix: 'TelegramPaymentTokenUpdateScreen.TelegramPaymentTokenUpdateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      name: telegramPaymentTokenDoc.name,
    }),
    [telegramPaymentTokenDoc],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        await setDoc(
          telegramPaymentTokenSnap.ref,
          {
            name: values.name,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );

        onComplete(telegramPaymentTokenSnap.ref);
      } catch (err) {
        showError(err);
      }
    },
    [telegramPaymentTokenSnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextFormControl
            isRequired
            label={t('name.label')}
            name="name"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            type="submit"
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
