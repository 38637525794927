import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { useTelegramBotsCollectionRef } from '../../common/collections/TelegramBots';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import TelegramBotRow from './TelegramBotRow';

export default function TelegramBotsScreen() {
  const customerSnap = useCustomerSnap();
  const telegramBotsCollectionRef = useTelegramBotsCollectionRef(customerSnap.ref);

  const { data: telegramBotsSnap } = useFirestoreCollection(
    query(
      telegramBotsCollectionRef,
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'TelegramBotsScreen' });

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.name')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {telegramBotsSnap.docs.map((telegramBotSnap) => (
              <TelegramBotRow key={telegramBotSnap.id} telegramBotSnap={telegramBotSnap} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
