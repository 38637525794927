import { Td, Tr } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDocData } from 'reactfire';

import { FineDoc } from '../../common/collections/Fines';

export type Props = {
  fineSnap: QueryDocumentSnapshot<FineDoc>;
};

export default function FineRow({ fineSnap }: Props) {
  const fineDoc = useMemo(() => fineSnap.data(), [fineSnap]);

  const { data: contractDoc } = useFirestoreDocData(fineDoc.contractRef);

  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.FineRow' });

  return (
    <Tr>
      <Td>
        {t('createdAt', { createdAt: fineDoc.createdAt.toDate() })}
      </Td>

      <Td whiteSpace="normal">
        {fineDoc.name}
      </Td>

      <Td isNumeric>
        {t('amount', {
          amount: fineDoc.amount / 100,
          formatParams: {
            amount: { currency: contractDoc.currency },
          },
        })}
      </Td>
    </Tr>
  );
}
