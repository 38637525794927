import {
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useStorage } from 'reactfire';
import { v4 as uuid } from 'uuid';

import useShowError from '../hooks/useShowError';
import { useCustomerSnap } from './CustomerSnapProvider';

export interface Props {
  label: string;
  name: string;
}

export default function DocumentFormControl({ label, name }: Props) {
  const showError = useShowError();

  const [, meta, helper] = useField<string>(name);

  const [uploadProgress, setUploadProgress] = useState<number | undefined>(undefined);

  const storage = useStorage();

  const customerSnap = useCustomerSnap();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return;
      }

      const file: File = e.target.files[0];

      setUploadProgress(0);

      const dest = ref(storage, `${customerSnap.id}/${uuid()}/${file.name}`);
      const uploadTask = uploadBytesResumable(dest, file, { contentType: 'application/pdf' });

      uploadTask.on('state_changed', {
        complete: () => {
          helper
            .setValue(dest.toString())
            .then(() => {
              setUploadProgress(undefined);
            })
            .catch(showError);
        },
        error: () => {
          setUploadProgress(undefined);
        },
        next: (snap) => {
          setUploadProgress(snap.bytesTransferred / snap.totalBytes);
        },
      });
    },
    [customerSnap.id, helper, showError, storage],
  );

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>
      {uploadProgress ? (
        <CircularProgress value={uploadProgress} />
      ) : null}

      <Input accept="application/pdf" onChange={handleChange} type="file" />
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
