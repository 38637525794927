import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
} & Omit<NumberInputProps, 'label' | 'name' | 'onChange' | 'precision' | 'value'>;

export default function MonetaryFormControl({
  isRequired = false,
  label,
  name,
  ...numberInputProps
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<number>(name);

  const handleChange = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      helper.setValue(Math.round(valueAsNumber * 100))
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <NumberInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...numberInputProps}
        onChange={handleChange}
        precision={2}
        value={input.value / 100}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
