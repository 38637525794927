import { doc, DocumentReference } from 'firebase/firestore';
import React, { createContext, useContext, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useUser } from 'reactfire';

import { UserDoc, useUsersCollectionRef } from '../../common/collections/Users';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type CheckAuthProps = {
  fallback: React.ReactElement;
};

const UserRefContext = createContext<DocumentReference<UserDoc> | null>(null);

export const useCurrentUserRef = () => {
  const userRef = useContext(UserRefContext);

  if (!userRef) {
    throw new Error('CheckAuthLayout');
  }

  return userRef;
};

export default function CheckAuthLayout({ fallback }: CheckAuthProps) {
  const { data: user } = useUser({ suspense: true });

  const customerSnap = useCustomerSnap();
  const usersCollectionRef = useUsersCollectionRef(customerSnap.ref);
  const userRef = useMemo(
    () => (user ? doc(usersCollectionRef, user.uid) : null),
    [user, usersCollectionRef],
  );

  if (!userRef) {
    return fallback;
  }

  return (
    <UserRefContext.Provider value={userRef}>
      <Outlet />
    </UserRefContext.Provider>
  );
}
