import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { usePropertiesCollectionRef } from '../../common/collections/Properties';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import PropertySnapProvider from '../../components/PropertySnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function PropertySnapLayout({ fallback }: Props) {
  const { propertyId } = useParams<{ propertyId: string }>();

  const customerSnap = useCustomerSnap();

  const propertiesCollectionRef = usePropertiesCollectionRef(customerSnap.ref);
  const propertyRef = useMemo(
    () => doc(propertiesCollectionRef, propertyId),
    [propertyId, propertiesCollectionRef],
  );

  const { data: propertySnap } = useFirestoreDoc(propertyRef);

  if (!propertySnap.exists()) {
    return fallback;
  }

  return (
    <PropertySnapProvider snap={propertySnap}>
      <Outlet />
    </PropertySnapProvider>
  );
}
