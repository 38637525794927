import {
  HStack,
  Icon,
  IconButton,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';

import { UnitDoc } from '../../common/collections/Units';
import ContractCell from './ContractCell';
import TenantCell from './TenantCell';

export type Props = {
  unitSnap: QueryDocumentSnapshot<UnitDoc>;
};

export default function UnitRow({ unitSnap }: Props) {
  const unitDoc = useMemo(() => unitSnap.data(), [unitSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'UnitsScreen.UnitRow' });

  return (
    <Tr>
      <Td>
        {unitDoc.name}
      </Td>

      <Td>
        {unitDoc.tenantRef ? (
          <TenantCell tenantRef={unitDoc.tenantRef} />
        ) : (
          <Text>{t('empty')}</Text>
        )}
      </Td>

      <Td>
        <ContractCell unitRef={unitSnap.ref} />
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={unitSnap.id}
            />
          </Tooltip>

          <Tooltip label={t('editButton.default')}>
            <IconButton
              aria-label={t('editButton.default')}
              as={RouterLink}
              icon={<Icon as={LuPenSquare} />}
              size="xs"
              to={`${unitSnap.id}/edit`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
