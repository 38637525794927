import {
  Card,
  Link,
  Table,
  TableContainer,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { useContractSnap } from '../../components/ContractSnapProvider';

export default function TenantCell() {
  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.TenantCell' });

  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const { data: tenantDoc } = useFirestoreDocData(contractDoc.tenantRef);

  return (
    <Card h="100%">
      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Tr>
            <Td>
              {t('fullName.label')}
            </Td>

            <Td isNumeric>
              <Link as={RouterLink} to={`../../tenants/${contractDoc.tenantRef.id}`}>
                {tenantDoc.firstName}
                {' '}
                {tenantDoc.lastName}
              </Link>
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('passport.number.label')}
            </Td>

            <Td isNumeric>
              {tenantDoc.passport.number}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('pesel.number.label')}
            </Td>

            <Td isNumeric>
              {tenantDoc.pesel.number}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('phoneNumber.label')}
            </Td>

            <Td isNumeric>
              {tenantDoc.phoneNumber}
            </Td>
          </Tr>
        </Table>
      </TableContainer>
    </Card>
  );
}
