import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Request {
  customerId: string;
  email: string;
  password: string;
}

export interface Response {
  userId: string;
}

export default function useCreateUser() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Request, Response>(functions, 'createUser'), [functions]);
}
