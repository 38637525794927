/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import Currency from '../Currency';
import { CustomerDoc } from './Customers';
import { TelegramBotDoc } from './TelegramBots';
import { TelegramPaymentTokenDoc } from './TelegramPaymentTokens';
import { UserDoc } from './Users';

export type PropertyFine = {
  amount: number;
  name: string;
};

export type PropertyDoc = {
  _v: 1;
  address: {
    building?: string;
    city?: string;
    country?: string;
    street?: string;
    unit?: string;
    zip?: string;
  };
  comments?: string;
  createdAt: Timestamp;
  currency: Currency;
  fines: PropertyFine[];
  isActive: boolean;
  managerRefs: DocumentReference<UserDoc>[];
  name: string;
  paymentDateShift: number;
  privacyPolicyUrl: string;
  telegramBotRef: DocumentReference<TelegramBotDoc>;
  telegramPaymentTokenRef: DocumentReference<TelegramPaymentTokenDoc>;
  timezone: string;
  updatedAt: Timestamp;
};

export const isPropertyDoc = (
  doc?: DocumentData,
): doc is PropertyDoc => true;

export const isPropertyRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<PropertyDoc> => ref.parent.id === 'properties' && ref.parent.parent?.parent?.id === 'customers';

function isPropertySnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
PropertyDoc
>;
function isPropertySnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
PropertyDoc
>;
function isPropertySnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
PropertyDoc
> | QueryDocumentSnapshot<
PropertyDoc
> {
  return isPropertyRef(snap.ref);
}

export { isPropertySnap };

export const getPropertiesCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'properties') as CollectionReference<PropertyDoc>;

export const usePropertiesCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getPropertiesCollectionRef(customerRef), [customerRef]);
