import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPenSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import { usePropertySnap } from '../../components/PropertySnapProvider';
import { useTenantSnap } from '../../components/TenantSnapProvider';
import InvoicesCell from './InvoicesCell';

export default function TenantScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'TenantScreen' });

  const navigate = useNavigate();

  const onEditClick = useCallback(
    () => {
      navigate('edit');
    },
    [navigate],
  );

  const tenantSnap = useTenantSnap();
  const tenantDoc = useMemo(() => tenantSnap.data(), [tenantSnap]);

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  return (
    <Grid gap={4} h="100%" templateColumns="repeat(3, 1fr)" templateRows="auto 1fr">
      <GridItem colSpan={3}>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('tenants.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">
                    {tenantDoc.firstName}
                    {' '}
                    {tenantDoc.lastName}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {tenantDoc.firstName}
                  {' '}
                  {tenantDoc.lastName}
                </Heading>

                <Tooltip label={t('editButton.default')}>
                  <IconButton
                    aria-label={t('editButton.default')}
                    icon={<Icon as={LuPenSquare} />}
                    onClick={onEditClick}
                  />
                </Tooltip>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem colSpan={3}>
        <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
          <InvoicesCell />
        </Suspense>
      </GridItem>
    </Grid>
  );
}
