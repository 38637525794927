import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  increment,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCigarette } from 'react-icons/lu';
import { useFirestore } from 'reactfire';
import * as yup from 'yup';

import { useFinesCollectionRef } from '../../common/collections/Fines';
import { useContractSnap } from '../../components/ContractSnapProvider';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import SelectFormControl from '../../components/SelectFormControl';
import TextareaFormControl from '../../components/TextareaFormControl';

export default function FinesButton() {
  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.FinesButton' });

  const customerSnap = useCustomerSnap();
  const finesCollectionRef = useFinesCollectionRef(customerSnap.ref);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const schema = useMemo(
    () => yup.object().shape({
      comment: yup
        .string()
        .label(t('comment.label'))
        .required(),
      fineIndex: yup
        .string()
        .label(t('fine.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      comment: '',
      fineIndex: '0',
    }),
    [],
  );

  const firestore = useFirestore();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const fine = contractDoc.fines[parseInt(values.fineIndex, 10)];

      const batch = writeBatch(firestore);

      batch.set(
        doc(finesCollectionRef),
        {
          _v: 1,
          amount: fine.amount,
          comment: values.comment,
          contractRef: contractSnap.ref,
          createdAt: Timestamp.now(),
          name: fine.name,
          propertyRef: contractDoc.propertyRef,
          tenantRef: contractDoc.tenantRef,
          updatedAt: Timestamp.now(),
        },
      );

      batch.set(
        contractSnap.ref,
        {
          balance: increment(-fine.amount),
          updatedAt: Timestamp.now(),
        },
        { merge: true },
      );

      await batch.commit();

      onClose();
    },
    [
      contractDoc.fines,
      contractDoc.propertyRef,
      contractDoc.tenantRef,
      contractSnap.ref,
      finesCollectionRef,
      firestore,
      onClose,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  if (!contractDoc.fines.length) {
    return null;
  }

  return (
    <>
      <Button
        colorScheme="red"
        leftIcon={<Icon as={LuCigarette} />}
        onClick={onOpen}
        variant="outline"
      >
        {t('default')}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validateOnBlur={validateAll}
          validateOnChange={validateAll}
          validationSchema={schema}
        >
          {({
            handleSubmit,
            isSubmitting,
          }) => (
            <ModalContent
              as="form"
              noValidate
              onSubmit={(e) => {
                setValidateAll(true);
                e.preventDefault();
                handleSubmit();
              }}
            >

              <ModalHeader>
                {t('default')}
              </ModalHeader>

              <ModalCloseButton />

              <ModalBody>
                <VStack alignItems="stretch" spacing={3}>
                  <SelectFormControl
                    label={t('fine.label')}
                    name="fineIndex"
                    options={contractDoc.fines.reduce(
                      (res, { amount, name }, i) => ({
                        ...res,
                        [String(i)]: t('fine.option', {
                          amount: amount / 100,
                          formatParams: {
                            amount: { currency: contractDoc.currency },
                          },
                          name,
                        }),
                      }),
                      {},
                    )}
                  />

                  <TextareaFormControl label={t('comment.label')} name="comment" />
                </VStack>
              </ModalBody>

              <ModalFooter gap={3}>
                <Button onClick={onClose} variant="ghost">
                  {t('cancelButton.default')}
                </Button>

                <Button
                  colorScheme="red"
                  isLoading={isSubmitting}
                  loadingText={t('createButton.loading')}
                  type="submit"
                >
                  {t('createButton.default')}
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </>
  );
}
