import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPenSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import { usePropertySnap } from '../../components/PropertySnapProvider';
import AddressCell from './AddressCell';
import QrCodeCell from './QrCodeCell';

export default function PropertyScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'PropertyScreen' });

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  const navigate = useNavigate();

  const onEditClick = useCallback(
    () => {
      navigate('edit');
    },
    [navigate],
  );

  return (
    <Grid gap={4} h="100%" templateColumns="400px repeat(2, 1fr)" templateRows="auto auto 1fr">
      <GridItem colSpan={3}>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {propertyDoc.name}
                </Heading>

                <Tooltip label={t('editButton.default')}>
                  <IconButton
                    aria-label={t('editButton.default')}
                    icon={<Icon as={LuPenSquare} />}
                    onClick={onEditClick}
                  />
                </Tooltip>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem>
        <QrCodeCell propertySnap={propertySnap} />
      </GridItem>

      <GridItem>
        <AddressCell />
      </GridItem>

      <GridItem colSpan={3} overflow="auto" />
    </Grid>
  );
}
