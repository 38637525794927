import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc } from './Customers';
import { PropertyDoc } from './Properties';
import { TenantDoc } from './Tenants';

export type UnitDoc = {
  _v: 1;
  createdAt: Timestamp;
  deposit: number;
  isActive: boolean;
  name: string;
  overdueDailyPenaltyPercent: number;
  periodAmount: number;
  propertyRef: DocumentReference<PropertyDoc>;
  tenantRef?: DocumentReference<TenantDoc>;
  updatedAt: Timestamp;
};

export const isUnitDoc = (
  doc?: DocumentData,
): doc is UnitDoc => true;

export const isUnitRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UnitDoc> => ref.parent.id === 'units' && ref.parent.parent?.parent?.id === 'customers';

function isUnitSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
UnitDoc
>;
function isUnitSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
UnitDoc
>;
function isUnitSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
UnitDoc
> | QueryDocumentSnapshot<
UnitDoc
> {
  return isUnitRef(snap.ref);
}

export { isUnitSnap };

export const getUnitsCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'units') as CollectionReference<UnitDoc>;

export const useUnitsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getUnitsCollectionRef(customerRef), [customerRef]);
