import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TenantDoc, useTenantsCollectionRef } from '../../common/collections/Tenants';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (tenantRef: DocumentReference<TenantDoc>) => void;
};

export default function TenantCreateForm({
  onComplete,
}: Props) {
  const customerSnap = useCustomerSnap();
  const tenantsCollectionRef = useTenantsCollectionRef(customerSnap.ref);

  const { t } = useTranslation('translations', { keyPrefix: 'TenantCreateScreen.TenantCreateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      firstName: yup
        .string()
        .label(t('firstName.label'))
        .required(),
      lastName: yup
        .string()
        .label(t('lastName.label'))
        .required(),
      passport: yup
        .object()
        .label(t('passport.label'))
        .required()
        .shape({
          number: yup
            .string()
            .label(t('passport.number.label'))
            .required(),
        }),
      pesel: yup
        .object()
        .label(t('pesel.label'))
        .required()
        .shape({
          number: yup
            .string()
            .label(t('pesel.number.label')),
        }),
      phoneNumber: yup
        .string()
        .label(t('phoneNumber.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      firstName: '',
      lastName: '',
      passport: {
        number: '',
      },
      pesel: {
        number: '',
      },
      phoneNumber: '',
    }),
    [],
  );

  const showError = useShowError();

  const propertySnap = usePropertySnap();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        const tenantRef = doc(tenantsCollectionRef);

        await setDoc(
          tenantRef,
          {
            _v: 1,
            createdAt: serverTimestamp(),
            firstName: values.firstName,
            lastName: values.lastName,
            passport: values.passport,
            pesel: values.pesel,
            phoneNumber: values.phoneNumber,
            propertyRef: propertySnap.ref,
            updatedAt: serverTimestamp(),
          },
        );

        onComplete(tenantRef);
      } catch (err) {
        showError(err);
      }
    },
    [tenantsCollectionRef, propertySnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('firstName.label')}
              name="firstName"
            />

            <TextFormControl
              isRequired
              label={t('lastName.label')}
              name="lastName"
            />
          </HStack>

          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('passport.number.label')}
              name="passport.number"
            />

            <TextFormControl
              label={t('pesel.number.label')}
              name="pesel.number"
            />
          </HStack>

          <TextFormControl
            isRequired
            label={t('phoneNumber.label')}
            name="phoneNumber"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            type="submit"
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
