/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import Currency from '../Currency';
import { CustomerDoc } from './Customers';
import { PropertyDoc, PropertyFine } from './Properties';
import { TenantDoc } from './Tenants';
import { UnitDoc } from './Units';

export enum ContractStatus {
  ACTIVE = 'ACTIVE', // The contract has been signed by all parties and is currently in effect.
  AWAITING_PAYMENT = 'AWAITING_PAYMENT', // The contract has been signed but is waiting for initial payment from the tenant.
  AWAITING_SIGNATURE = 'AWAITING_SIGNATURE', // The contract has been finalized but is waiting for one or more signatures from the involved parties.
  CANCELLED = 'CANCELLED', // The contract was cancelled before it was activated, often during the Draft or Pending Approval stages.
  DRAFT = 'DRAFT', // The contract is being prepared and has not yet been finalized or signed.
  EXPIRED = 'EXPIRED', // The contract has reached its end date and is no longer active.
  SUSPENDED = 'SUSPENDED', // The contract has been temporarily halted, possibly due to non-compliance, pending issues, or other reasons.
  TERMINATED = 'TERMINATED', // The contract was ended before its natural expiration date, either by mutual agreement or due to breach of terms.
}

export type ContractDoc = {
  _v: 1;
  balance: number;
  createdAt: Timestamp;
  currency: Currency;
  deposit: number;
  documentStorageRef?: string;
  endDate: string;
  fines: PropertyFine[];
  lastPeriodEndsAt?: Timestamp;
  name: string;
  overdueDailyPenaltyPercent: number;
  paymentDateShift: number;
  paymentDue?: Timestamp;
  periodAmount: number;
  propertyRef: DocumentReference<PropertyDoc>;
  signedAt?: Timestamp;
  startDate: string;
  status: ContractStatus;
  tenantRef: DocumentReference<TenantDoc>;
  timezone: string;
  unitRef: DocumentReference<UnitDoc>;
  updatedAt: Timestamp;
};

export const isContractDoc = (
  doc?: DocumentData,
): doc is ContractDoc => true;

export const isContractRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ContractDoc> => ref.parent.id === 'contracts' && ref.parent.parent?.parent?.id === 'customers';

function isContractSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
ContractDoc
>;
function isContractSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
ContractDoc
>;
function isContractSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
ContractDoc
> | QueryDocumentSnapshot<
ContractDoc
> {
  return isContractRef(snap.ref);
}

export { isContractSnap };

export const getContractsCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'contracts') as CollectionReference<ContractDoc>;

export const useContractsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getContractsCollectionRef(customerRef), [customerRef]);
