import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { UserDoc, UserRole } from '../../common/collections/Users';
import RadioFormControl from '../../components/RadioFormControl';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (userRef: DocumentReference<UserDoc>) => void;
  userSnap: QueryDocumentSnapshot<UserDoc>;
};

export default function UserUpdateForm({
  onComplete,
  userSnap,
}: Props) {
  const userDoc = useMemo(() => userSnap.data(), [userSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'UserUpdateScreen.UserUpdateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      firstName: yup
        .string()
        .label(t('firstName.label'))
        .required(),
      lastName: yup
        .string()
        .label(t('lastName.label'))
        .required(),
      phoneNumber: yup
        .string()
        .label(t('phoneNumber.label'))
        .required(),
      role: yup
        .string()
        .label(t('role.label'))
        .oneOf(Object.values(UserRole))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      firstName: userDoc.firstName,
      lastName: userDoc.lastName,
      phoneNumber: userDoc.phoneNumber,
      role: userDoc.role,
    }),
    [userDoc],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        await setDoc(
          userSnap.ref,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            role: values.role,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );

        onComplete(userSnap.ref);
      } catch (err) {
        showError(err);
      }
    },
    [userSnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('firstName.label')}
              name="firstName"
            />

            <TextFormControl
              isRequired
              label={t('lastName.label')}
              name="lastName"
            />
          </HStack>

          <RadioFormControl
            isRequired
            label={t('role.label')}
            name="role"
            options={UserRole}
          />

          <TextFormControl
            isRequired
            label={t('phoneNumber.label')}
            name="phoneNumber"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            type="submit"
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
