import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { PropertyDoc } from '../common/collections/Properties';

export type Props = {
  snap: QueryDocumentSnapshot<PropertyDoc>;
};

const Context = createContext<null | QueryDocumentSnapshot<PropertyDoc>>(null);

export const usePropertySnap = () => {
  const contractSnap = useContext(Context);

  if (!contractSnap) {
    throw new Error('PropertySnapProvider');
  }

  return contractSnap;
};

export default function PropertySnapProvider({
  children,
  snap,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={snap}>
      {children}
    </Context.Provider>
  );
}
