import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useTelegramBotSnap } from '../../components/TelegramBotSnapProvider';
import { useTelegramPaymentTokenSnap } from '../../components/TelegramPaymentTokenSnapProvider';
import TelegramPaymentTokenUpdateForm from './TelegramPaymentTokenUpdateForm';

export default function TelegramPaymentTokenUpdateScreen() {
  const telegramBotSnap = useTelegramBotSnap();
  const telegramBotDoc = useMemo(() => telegramBotSnap.data(), [telegramBotSnap]);

  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const navigate = useNavigate();

  const onComplete = useCallback(
    () => {
      navigate('../', { replace: true });
    },
    [navigate],
  );

  const telegramPaymentTokenSnap = useTelegramPaymentTokenSnap();
  const telegramPaymentTokenDoc = useMemo(
    () => telegramPaymentTokenSnap.data(),
    [telegramPaymentTokenSnap],
  );

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../../..">{nav('telegramBots.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{telegramBotDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{nav('telegramPaymentTokens.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{telegramPaymentTokenDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('telegramPaymentTokens.update.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {telegramPaymentTokenDoc.name}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <TelegramPaymentTokenUpdateForm
          onComplete={onComplete}
          telegramPaymentTokenSnap={telegramPaymentTokenSnap}
        />
      </GridItem>
    </Grid>
  );
}
