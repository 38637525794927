import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';

import { ContractDoc, ContractStatus } from '../../common/collections/Contracts';
import useContractStatusColorScheme from '../../hooks/useContractStatusColorScheme';
import TenantCell from './TenantCell';
import UnitCell from './UnitCell';

export type Props = {
  contractSnap: QueryDocumentSnapshot<ContractDoc>;
};

export default function ContractRow({ contractSnap }: Props) {
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'ContractsScreen.ContractRow' });

  const statusColorScheme = useContractStatusColorScheme(contractDoc.status);

  return (
    <Tr>
      <Td>
        {contractDoc.name}
      </Td>

      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <TenantCell contractSnap={contractSnap} />
        </Suspense>
      </Td>

      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <UnitCell contractSnap={contractSnap} />
        </Suspense>
      </Td>

      <Td>
        {t('startDate', {
          startDate: new Date(contractDoc.startDate),
        })}
      </Td>

      <Td>
        {t('endDate', {
          endDate: new Date(contractDoc.endDate),
        })}
      </Td>

      <Td>
        <Badge colorScheme={statusColorScheme}>
          {contractDoc.status}
        </Badge>
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={contractSnap.id}
            />
          </Tooltip>

          {contractDoc.status === ContractStatus.DRAFT ? (
            <Tooltip label={t('editButton.default')}>
              <IconButton
                aria-label={t('editButton.default')}
                as={RouterLink}
                icon={<Icon as={LuPenSquare} />}
                size="xs"
                to={`${contractSnap.id}/edit`}
              />
            </Tooltip>
          ) : null}
        </HStack>
      </Td>
    </Tr>
  );
}
