import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { TelegramBotDoc } from '../common/collections/TelegramBots';

export type Props = {
  snap: QueryDocumentSnapshot<TelegramBotDoc>;
};

const Context = createContext<null | QueryDocumentSnapshot<TelegramBotDoc>>(null);

export const useTelegramBotSnap = () => {
  const contractSnap = useContext(Context);

  if (!contractSnap) {
    throw new Error('TelegramBotSnapProvider');
  }

  return contractSnap;
};

export default function TelegramBotSnapProvider({
  children,
  snap,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={snap}>
      {children}
    </Context.Provider>
  );
}
