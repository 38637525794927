import { Link } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ContractDoc } from '../../common/collections/Contracts';

export type Props = {
  contractRef: DocumentReference<ContractDoc>;
};

export default function ContractCell({ contractRef }: Props) {
  const { data: contractDoc } = useFirestoreDocData(contractRef);

  return (
    <Link as={RouterLink} to={`../contracts/${contractRef.id}`}>
      {contractDoc.name}
    </Link>
  );
}
