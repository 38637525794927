/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';

export type CustomerDoc = {
  _v: 1;
  authTenantId: string;
  createdAt: Timestamp;
  name: string;
  updatedAt: Timestamp;
};

export const isCustomerDoc = (
  doc?: DocumentData,
): doc is CustomerDoc => true;

export const isCustomerRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CustomerDoc> => ref.parent.id === 'customers';

function isCustomerSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
CustomerDoc
>;
function isCustomerSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
CustomerDoc
>;
function isCustomerSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
CustomerDoc
> | QueryDocumentSnapshot<
CustomerDoc
> {
  return isCustomerRef(snap.ref);
}

export { isCustomerSnap };

export const getCustomersCollectionRef = (firestore: Firestore) => collection(firestore, 'customers') as CollectionReference<CustomerDoc>;

export const useCustomersCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getCustomersCollectionRef(firestore), [firestore]);
};
