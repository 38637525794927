import { Link, Wrap } from '@chakra-ui/react';
import {
  limit,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { TenantDoc } from '../../common/collections/Tenants';
import { useUnitsCollectionRef } from '../../common/collections/Units';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type Props = {
  tenantSnap: QueryDocumentSnapshot<TenantDoc>;
};

export default function UnitCell({ tenantSnap }: Props) {
  const customerSnap = useCustomerSnap();

  const unitsCollectionRef = useUnitsCollectionRef(customerSnap.ref);
  const { data: unitsSnap } = useFirestoreCollection(query(
    unitsCollectionRef,
    where('tenantRef', '==', tenantSnap.ref),
    limit(1),
  ));

  return (
    <Wrap>
      {unitsSnap.docs.map((unitSnap) => (
        <Link as={RouterLink} to={`../units/${unitSnap.id}`}>
          {unitSnap.data().name}
        </Link>
      ))}
    </Wrap>
  );
}
