import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ContractDoc } from '../../common/collections/Contracts';
import { TenantDoc } from '../../common/collections/Tenants';
import { UnitDoc } from '../../common/collections/Units';
import Currency from '../../common/Currency';
import DocumentFormControl from '../../components/DocumentFormControl';
import MonetaryFormControl from '../../components/MonetaryFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import SelectFormControl from '../../components/SelectFormControl';
import TenantFormControl from '../../components/TenantFormControl';
import TextFormControl from '../../components/TextFormControl';
import UnitFormControl from '../../components/UnitFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  contractSnap: QueryDocumentSnapshot<ContractDoc>;
  onComplete: (contractRef: DocumentReference<ContractDoc>) => void;
};

export default function ContractUpdateForm({
  contractSnap,
  onComplete,
}: Props) {
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'ContractUpdateScreen.ContractUpdateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      currency: yup
        .string<Currency>()
        .label(t('currency.label'))
        .oneOf(Object.values(Currency))
        .required(),
      deposit: yup
        .number()
        .label(t('deposit.label'))
        .required(),
      documentStorageRef: yup
        .string()
        .label(t('documentStorageRef.label')),
      endDate: yup
        .string()
        .label(t('endDate.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      overdueDailyPenaltyPercent: yup
        .number()
        .label(t('overdueDailyPenaltyPercent.label'))
        .required(),
      paymentDateShift: yup
        .number()
        .min(-28)
        .max(28)
        .label(t('paymentDateShift.label'))
        .required(),
      periodAmount: yup
        .number()
        .label(t('periodAmount.label'))
        .required(),
      startDate: yup
        .string()
        .label(t('startDate.label'))
        .required(),
      tenantRef: yup
        .mixed<DocumentReference<TenantDoc>>()
        .label(t('tenantRef.label'))
        .required(),
      timezone: yup
        .string()
        .label(t('timezone.label'))
        .required(),
      unitRef: yup
        .mixed<DocumentReference<UnitDoc>>()
        .label(t('unitRef.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      currency: contractDoc.currency,
      deposit: contractDoc.deposit,
      documentStorageRef: contractDoc.documentStorageRef,
      endDate: contractDoc.endDate,
      name: contractDoc.name,
      overdueDailyPenaltyPercent: contractDoc.overdueDailyPenaltyPercent,
      paymentDateShift: contractDoc.paymentDateShift ?? 0,
      periodAmount: contractDoc.periodAmount,
      startDate: contractDoc.startDate,
      tenantRef: contractDoc.tenantRef,
      timezone: contractDoc.timezone,
      unitRef: contractDoc.unitRef,
    }),
    [contractDoc],
  );

  const showError = useShowError();

  const propertySnap = usePropertySnap();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        if (
          !values.tenantRef
          || !values.unitRef
        ) {
          return;
        }

        await setDoc(
          contractSnap.ref,
          {
            currency: values.currency,
            deposit: values.deposit,
            documentStorageRef: values.documentStorageRef,
            endDate: values.endDate,
            name: values.name,
            overdueDailyPenaltyPercent: values.overdueDailyPenaltyPercent,
            paymentDateShift: values.paymentDateShift ?? 0,
            periodAmount: values.periodAmount,
            startDate: values.startDate,
            tenantRef: values.tenantRef,
            timezone: values.timezone,
            unitRef: values.unitRef,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );

        onComplete(contractSnap.ref);
      } catch (err) {
        showError(err);
      }
    },
    [contractSnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextFormControl
            isRequired
            label={t('name.label')}
            name="name"
          />

          <HStack gap={4}>
            <Suspense fallback={null}>
              <UnitFormControl
                isRequired
                label={t('unitRef.label')}
                name="unitRef"
                propertyRef={propertySnap.ref}
              />
            </Suspense>

            <Suspense fallback={null}>
              <TenantFormControl
                isRequired
                label={t('tenantRef.label')}
                name="tenantRef"
                propertyRef={propertySnap.ref}
              />
            </Suspense>
          </HStack>

          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('startDate.label')}
              name="startDate"
              type="date"
            />

            <TextFormControl
              isRequired
              label={t('endDate.label')}
              name="endDate"
              type="date"
            />
          </HStack>

          <HStack gap={4}>
            <SelectFormControl
              isRequired
              label={t('timezone.label')}
              name="timezone"
              options={moment.tz.names().reduce((r, tz) => ({ ...r, [tz]: tz }), {})}
            />

            <SelectFormControl
              isRequired
              label={t('currency.label')}
              name="currency"
              options={Currency}
            />
          </HStack>

          <HStack gap={4}>
            <MonetaryFormControl
              isRequired
              label={t('periodAmount.label')}
              name="periodAmount"
            />

            <MonetaryFormControl
              isRequired
              label={t('deposit.label')}
              name="deposit"
            />
          </HStack>

          <NumberFormControl
            isRequired
            label={t('overdueDailyPenaltyPercent.label')}
            max={10}
            min={0}
            name="overdueDailyPenaltyPercent"
            step={1}
          />

          <NumberFormControl
            isRequired
            label={t('paymentDateShift.label')}
            max={28}
            min={-28}
            name="paymentDateShift"
            step={1}
          />

          <DocumentFormControl
            label={t('documentStorageRef.label')}
            name="documentStorageRef"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            type="submit"
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
