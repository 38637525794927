import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ContractDoc } from '../../common/collections/Contracts';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import ContractCreateForm from './ContractCreateForm';

export default function ContractCreateScreen() {
  const navigate = useNavigate();
  const onComplete = useCallback(
    (contractRef: DocumentReference<ContractDoc>) => {
      navigate(`../${contractRef.id}`, { replace: true });
    },
    [navigate],
  );

  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'ContractCreateScreen' });

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('contracts.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('contracts.create.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {t('title')}
                </Heading>
              </HStack>

            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <ContractCreateForm onComplete={onComplete} />
      </GridItem>
    </Grid>
  );
}
