import {
  Button,
  Card,
  Center,
  HStack,
  Icon,
  Image,
  Link,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import QRCode from 'qrcode';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuDownload, LuLink } from 'react-icons/lu';
import { useFirestoreDocData } from 'reactfire';

import { PropertyDoc } from '../../common/collections/Properties';

export type Props = {
  propertySnap: QueryDocumentSnapshot<PropertyDoc>;
};

export default function QrCodeCell({ propertySnap }: Props) {
  const { t } = useTranslation('translations', { keyPrefix: 'PropertyScreen' });

  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  const { data: telegramBotDoc } = useFirestoreDocData(propertyDoc.telegramBotRef);

  const botUrl = useMemo(
    () => {
      const url = new URL(`https://t.me/${telegramBotDoc.username}`);
      url.searchParams.set('start', propertySnap.id);
      return url.toString();
    },
    [propertySnap.id, telegramBotDoc.username],
  );

  const [qrCode, setQrCode] = useState<string>();
  useEffect(
    () => {
      QRCode.toDataURL(botUrl, { errorCorrectionLevel: 'H', scale: 16 })
        .then(setQrCode)
        .catch(() => { });
    },
    [botUrl],
  );

  const { hasCopied, onCopy } = useClipboard(botUrl);

  return (
    <Card h="100%" overflow="hidden" w="100%">
      <Center h="100%" w="100%">
        <VStack>
          <Image src={qrCode} />

          <HStack p={4} pt={2}>
            <Button
              as={Link}
              download={`${_.snakeCase(propertyDoc.name)}_qr_code.png`}
              href={qrCode}
              leftIcon={<Icon as={LuDownload} />}
            >
              {t('download.default')}
            </Button>

            <Button
              leftIcon={<Icon as={LuLink} />}
              onClick={onCopy}
            >
              {t(hasCopied ? 'copy.copied' : 'copy.default')}
            </Button>
          </HStack>
        </VStack>
      </Center>
    </Card>
  );
}
