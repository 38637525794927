import {
  Card,
  Table,
  TableContainer,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePropertySnap } from '../../components/PropertySnapProvider';

export default function AddressCell() {
  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'PropertyScreen.AddressCell' });

  return (
    <Card h="100%">
      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Tr>
            <Td>{t('address.zip.label')}</Td>
            <Td>{propertyDoc.address.zip}</Td>
          </Tr>
          <Tr>
            <Td>{t('address.country.label')}</Td>
            <Td>{propertyDoc.address.country}</Td>
          </Tr>
          <Tr>
            <Td>{t('address.city.label')}</Td>
            <Td>{propertyDoc.address.city}</Td>
          </Tr>
          <Tr>
            <Td>{t('address.street.label')}</Td>
            <Td>{propertyDoc.address.street}</Td>
          </Tr>
          <Tr>
            <Td>{t('address.building.label')}</Td>
            <Td>{propertyDoc.address.building}</Td>
          </Tr>
          <Tr>
            <Td>{t('address.unit.label')}</Td>
            <Td>{propertyDoc.address.unit}</Td>
          </Tr>
        </Table>
      </TableContainer>
    </Card>
  );
}
