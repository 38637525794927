import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { useFinesCollectionRef } from '../../common/collections/Fines';
import { useContractSnap } from '../../components/ContractSnapProvider';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import FineRow from './FineRow';

export default function FinesCell() {
  const customerSnap = useCustomerSnap();
  const contractSnap = useContractSnap();

  const finesCollectionRef = useFinesCollectionRef(customerSnap.ref);
  const { data: finesSnap } = useFirestoreCollection(
    query(
      finesCollectionRef,
      where('contractRef', '==', contractSnap.ref),
      orderBy('createdAt', 'desc'),
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.FinesCell' });

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer flex={1} overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.createdAt')}</Th>
              <Th>{t('tableHeader.name')}</Th>
              <Th isNumeric>{t('tableHeader.amount')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {finesSnap.docs.map((fineSnap) => (
              <FineRow fineSnap={fineSnap} key={fineSnap.id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
