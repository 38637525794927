import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export interface Request {
  customerId: string;
  token: string;
}

export interface Response {
  telegramBotId: string;
}

export default function useTelegramAddBot() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Request, Response>(functions, 'telegram-addBot'), [functions]);
}
