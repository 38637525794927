import {
  Card,
  Table,
  TableContainer,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useContractSnap } from '../../components/ContractSnapProvider';

export default function ContractDatesCell() {
  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.ContractDatesCell' });

  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  return (
    <Card h="100%">
      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Tr>
            <Td>
              {t('startDate.label')}
            </Td>

            <Td isNumeric>
              {t('startDate.value', { startDate: new Date(contractDoc.startDate) })}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('endDate.label')}
            </Td>

            <Td isNumeric>
              {t('endDate.value', { endDate: new Date(contractDoc.endDate) })}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('signedAt.label')}
            </Td>

            <Td isNumeric>
              {contractDoc.signedAt ? (
                t('signedAt.value', { signedAt: contractDoc.signedAt.toDate() })
              ) : null}
            </Td>
          </Tr>
        </Table>
      </TableContainer>
    </Card>
  );
}
