import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { CustomerDoc } from '../common/collections/Customers';

export type Props = {
  snap: QueryDocumentSnapshot<CustomerDoc>;
};

const Context = createContext<null | QueryDocumentSnapshot<CustomerDoc>>(null);

export const useCustomerSnap = () => {
  const contractSnap = useContext(Context);

  if (!contractSnap) {
    throw new Error('CustomerSnapProvider');
  }

  return contractSnap;
};

export default function CustomerSnapProvider({
  children,
  snap,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={snap}>
      {children}
    </Context.Provider>
  );
}
