import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Text,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  query,
  where,
} from 'firebase/firestore';
import { useField } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { PropertyDoc } from '../common/collections/Properties';
import { TenantDoc, useTenantsCollectionRef } from '../common/collections/Tenants';
import useShowError from '../hooks/useShowError';
import { useCustomerSnap } from './CustomerSnapProvider';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
  propertyRef: DocumentReference<PropertyDoc>;
}

export default function TenantFormControl({
  isRequired = false,
  label,
  name,
  propertyRef,
}: Props) {
  const showError = useShowError();
  const customerSnap = useCustomerSnap();
  const tenantsCollectionRef = useTenantsCollectionRef(customerSnap.ref);

  const { data: tenantsSnap } = useFirestoreCollection(
    query(
      tenantsCollectionRef,
      where('propertyRef', '==', propertyRef),
    ),
  );

  const [input, meta, helper] = useField<DocumentReference<TenantDoc> | null>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helper.setValue(
        e.target.value.length ? doc(tenantsCollectionRef, e.target.value) : null,
      )
        .catch(showError);
    },
    [helper, tenantsCollectionRef, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      {tenantsSnap.docs.length ? (
        <Select onChange={handleChange} value={input.value?.id ?? undefined}>
          <option value="">-</option>
          {tenantsSnap.docs.map((tenantSnap) => (
            <option key={tenantSnap.id} value={tenantSnap.id}>
              {tenantSnap.data().firstName}
              {' '}
              {tenantSnap.data().lastName}
            </option>
          ))}
        </Select>
      ) : (
        <Box
          alignItems="center"
          border={1}
          borderColor="inherit"
          borderRadius="md"
          borderStyle="solid"
          display="flex"
          fontSize="md"
          h={10}
          px={4}
        >
          <Text flex={1}>No tenants found.</Text>
          <Button as={Link} size="xs" target="_blank" to={`/${customerSnap.id}/properties/${propertyRef.id}/tenants/new`}>Create tenant</Button>
        </Box>
      )}
      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
