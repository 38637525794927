import { Link } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { TenantDoc } from '../../common/collections/Tenants';

export type Props = {
  tenantRef: DocumentReference<TenantDoc>;
};

export default function TenantCell({ tenantRef }: Props) {
  const { data: tenantDoc } = useFirestoreDocData(tenantRef);

  return (
    <Link as={RouterLink} to={`../tenants/${tenantRef.id}`}>
      {tenantDoc.firstName}
      {' '}
      {tenantDoc.lastName}
    </Link>
  );
}
