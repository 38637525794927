import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Link,
  Td,
  Tooltip,
  Tr,
  Wrap,
} from '@chakra-ui/react';
import { query, QueryDocumentSnapshot, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { usePropertiesCollectionRef } from '../../common/collections/Properties';
import { UserDoc } from '../../common/collections/Users';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type Props = {
  userSnap: QueryDocumentSnapshot<UserDoc>;
};

export default function UserRow({ userSnap }: Props) {
  const userDoc = useMemo(() => userSnap.data(), [userSnap]);

  const customerSnap = useCustomerSnap();
  const propertiesCollectionRef = usePropertiesCollectionRef(customerSnap.ref);
  const { data: propertiesSnap } = useFirestoreCollection(
    query(
      propertiesCollectionRef,
      where('managerRefs', 'array-contains', userSnap.ref),
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'UsersScreen.UserRow' });

  return (
    <Tr>
      <Td>
        {userDoc.firstName}
        {' '}
        {userDoc.lastName}
      </Td>

      <Td>
        <Badge>
          {userDoc.role}
        </Badge>
      </Td>

      <Td>
        <Wrap>
          {propertiesSnap.docs.map((propertySnap) => (
            <Link as={RouterLink} key={propertySnap.id} to={`../properties/${propertySnap.id}`}>
              {propertySnap.data().name}
            </Link>
          ))}
        </Wrap>
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={userSnap.id}
            />
          </Tooltip>

          <Tooltip label={t('editButton.default')}>
            <IconButton
              aria-label={t('editButton.default')}
              as={RouterLink}
              icon={<Icon as={LuPenSquare} />}
              size="xs"
              to={`${userSnap.id}/edit`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
