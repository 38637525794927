import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { usePropertySnap } from '../../components/PropertySnapProvider';
import { useUnitSnap } from '../../components/UnitSnapProvider';
import UnitUpdateForm from './UnitUpdateForm';

export default function UnitUpdateScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const navigate = useNavigate();

  const onComplete = useCallback(
    () => {
      navigate('../', { replace: true });
    },
    [navigate],
  );

  const unitSnap = useUnitSnap();
  const unitDoc = useMemo(() => unitSnap.data(), [unitSnap]);

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{nav('units.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{unitDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('units.update.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {unitDoc.name}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <UnitUpdateForm onComplete={onComplete} unitSnap={unitSnap} />
      </GridItem>
    </Grid>
  );
}
