import { Button, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  TelegramPaymentTokenDoc,
  useTelegramPaymentTokensCollectionRef,
} from '../../common/collections/TelegramPaymentTokens';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { useTelegramBotSnap } from '../../components/TelegramBotSnapProvider';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (telegramPaymentTokenRef: DocumentReference<TelegramPaymentTokenDoc>) => void;
};

export default function TelegramPaymentTokenCreateForm({
  onComplete,
}: Props) {
  const telegramBotSnap = useTelegramBotSnap();

  const customerSnap = useCustomerSnap();
  const telegramPaymentTokensCollectionRef = useTelegramPaymentTokensCollectionRef(
    customerSnap.ref,
  );

  const { t } = useTranslation('translations', { keyPrefix: 'TelegramPaymentTokenCreateScreen.TelegramPaymentTokenCreateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      token: yup
        .string()
        .label(t('token.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      name: '',
      token: '',
    }),
    [],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        const telegramPaymentTokenRef = doc(telegramPaymentTokensCollectionRef);

        await setDoc(
          telegramPaymentTokenRef,
          {
            _v: 1,
            createdAt: serverTimestamp(),
            name: values.name,
            telegramBotRef: telegramBotSnap.ref,
            token: values.token,
            updatedAt: serverTimestamp(),
          },
        );

        onComplete(telegramPaymentTokenRef);
      } catch (err) {
        showError(err);
      }
    },
    [onComplete, showError, telegramBotSnap.ref, telegramPaymentTokensCollectionRef],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextFormControl
            isRequired
            label={t('name.label')}
            name="name"
          />

          <TextFormControl
            isRequired
            label={t('token.label')}
            name="token"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            type="submit"
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
