import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { TelegramPaymentTokenDoc } from '../../common/collections/TelegramPaymentTokens';
import { useTelegramBotSnap } from '../../components/TelegramBotSnapProvider';
import TelegramPaymentTokenCreateForm from './TelegramPaymentTokenCreateForm';

export default function TelegramPaymentTokenCreateScreen() {
  const telegramBotSnap = useTelegramBotSnap();
  const telegramBotDoc = useMemo(() => telegramBotSnap.data(), [telegramBotSnap]);

  const navigate = useNavigate();
  const onComplete = useCallback(
    (telegramPaymentTokenRef: DocumentReference<TelegramPaymentTokenDoc>) => {
      navigate(`../${telegramPaymentTokenRef.id}`, { replace: true });
    },
    [navigate],
  );

  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'TelegramPaymentTokenCreateScreen' });

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{nav('telegramBots.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{telegramBotDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('telegramPaymentTokens.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('telegramPaymentTokens.create.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {t('title')}
                </Heading>
              </HStack>

            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <TelegramPaymentTokenCreateForm onComplete={onComplete} />
      </GridItem>
    </Grid>
  );
}
