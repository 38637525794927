import {
  Button,
  Divider,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
} from '@chakra-ui/react';
import { LuChevronDown, LuUser } from 'react-icons/lu';

import ColorMode from './ColorMode';
import Documentation from './Documentation';
import Help from './Help';
import Languages from './Languages';
import Logout from './Logout';

export default function Settings() {
  return (
    <>
      <Divider />

      <HStack justifyContent="flex-end">
        <Languages />

        <Menu>
          <MenuButton
            as={Button}
            iconSpacing={0}
            leftIcon={<Icon as={LuUser} />}
            rightIcon={<Icon as={LuChevronDown} />}
          />

          <MenuList>
            <ColorMode />
            <Documentation />
            <Help />

            <MenuDivider />

            <Logout />
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
}
