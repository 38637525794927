enum Currency {
  AED = 'AED', // United Arab Emirates Dirham
  ALL = 'ALL', // Albanian Lek
  AUD = 'AUD', // Australian Dollar
  BAM = 'BAM', // Bosnia-Herzegovina Convertible Mark
  BGN = 'BGN', // Bulgarian Lev
  BRL = 'BRL', // Brazilian Real
  BYN = 'BYN', // Belarusian Ruble
  CAD = 'CAD', // Canadian Dollar
  CHF = 'CHF', // Swiss Franc
  CNY = 'CNY', // Chinese Yuan
  CZK = 'CZK', // Czech Koruna
  DKK = 'DKK', // Danish Krone
  EUR = 'EUR', // Euro
  GBP = 'GBP', // British Pound Sterling
  GEL = 'GEL', // Georgian Lari
  HKD = 'HKD', // Hong Kong Dollar
  HRK = 'HRK', // Croatian Kuna (until replaced by Euro)
  HUF = 'HUF', // Hungarian Forint
  IDR = 'IDR', // Indonesian Rupiah
  INR = 'INR', // Indian Rupee
  ISK = 'ISK', // Icelandic Krona
  JPY = 'JPY', // Japanese Yen
  KRW = 'KRW', // South Korean Won
  MDL = 'MDL', // Moldovan Leu
  MKD = 'MKD', // Macedonian Denar
  MXN = 'MXN', // Mexican Peso
  NOK = 'NOK', // Norwegian Krone
  NZD = 'NZD', // New Zealand Dollar
  PLN = 'PLN', // Polish Zloty
  RON = 'RON', // Romanian Leu
  RSD = 'RSD', // Serbian Dinar
  RUB = 'RUB', // Russian Ruble
  SAR = 'SAR', // Saudi Riyal
  SEK = 'SEK', // Swedish Krona
  SGD = 'SGD', // Singapore Dollar
  THB = 'THB', // Thai Baht
  TRY = 'TRY', // Turkish Lira
  UAH = 'UAH', // Ukrainian Hryvnia
  USD = 'USD', // United States Dollar
  ZAR = 'ZAR', // South African Rand
}

export default Currency;
