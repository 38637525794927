import {
  Card,
  Link,
  Table,
  TableContainer,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { useComplainSnap } from '../../components/ComplainSnapProvider';

export default function TenantCell() {
  const { t } = useTranslation('translations', { keyPrefix: 'ComplainScreen.TenantCell' });

  const complainSnap = useComplainSnap();
  const complainDoc = useMemo(() => complainSnap.data(), [complainSnap]);

  const { data: tenantDoc } = useFirestoreDocData(complainDoc.tenantRef);

  return (
    <Card h="100%">
      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Tr>
            <Td>
              {t('fullName.label')}
            </Td>

            <Td isNumeric>
              <Link as={RouterLink} to={`../../../properties/${complainDoc.propertyRef.id}/tenants/${complainDoc.tenantRef.id}`}>
                {tenantDoc.firstName}
                {' '}
                {tenantDoc.lastName}
              </Link>
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('phoneNumber.label')}
            </Td>

            <Td isNumeric>
              {tenantDoc.phoneNumber}
            </Td>
          </Tr>
        </Table>
      </TableContainer>
    </Card>
  );
}
