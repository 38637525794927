import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ComplainsTable from './ComplainsTable';

export default function ComplainsScreen() {
  const { t } = useTranslation('translations', { keyPrefix: 'ComplainsScreen' });
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('complains.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {t('title')}
                </Heading>
              </HStack>

            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
          <ComplainsTable />
        </Suspense>
      </GridItem>
    </Grid>
  );
}
