import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useUserSnap } from '../../components/UserSnapProvider';
import UserUpdateForm from './UserUpdateForm';

export default function UserUpdateScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const navigate = useNavigate();

  const onComplete = useCallback(
    () => {
      navigate('../', { replace: true });
    },
    [navigate],
  );

  const userSnap = useUserSnap();
  const userDoc = useMemo(() => userSnap.data(), [userSnap]);

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{nav('users.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">
                    {userDoc.firstName}
                    {' '}
                    {userDoc.lastName}
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('users.update.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {userDoc.firstName}
                  {' '}
                  {userDoc.lastName}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <UserUpdateForm onComplete={onComplete} userSnap={userSnap} />
      </GridItem>
    </Grid>
  );
}
