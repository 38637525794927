import { MenuItem } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuLogOut } from 'react-icons/lu';
import { useAuth } from 'reactfire';

import useShowError from '../../hooks/useShowError';

export default function Logout() {
  const { t } = useTranslation('translations', { keyPrefix: 'MainLayout' });

  const auth = useAuth();
  const showError = useShowError();

  const handleLogoutClick = useCallback(
    () => {
      auth.signOut().catch(showError);
    },
    [auth, showError],
  );

  return (
    <MenuItem icon={<LuLogOut />} onClick={handleLogoutClick} textColor="red.500">
      {t('logoutButton.default')}
    </MenuItem>
  );
}
