import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useTenantsCollectionRef } from '../../common/collections/Tenants';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import TenantSnapProvider from '../../components/TenantSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function TenantSnapLayout({ fallback }: Props) {
  const { tenantId } = useParams<{ tenantId: string }>();

  const customerSnap = useCustomerSnap();

  const tenantsCollectionRef = useTenantsCollectionRef(customerSnap.ref);
  const tenantRef = useMemo(
    () => doc(tenantsCollectionRef, tenantId),
    [tenantId, tenantsCollectionRef],
  );

  const { data: tenantSnap } = useFirestoreDoc(tenantRef);

  if (!tenantSnap.exists()) {
    return fallback;
  }

  return (
    <TenantSnapProvider snap={tenantSnap}>
      <Outlet />
    </TenantSnapProvider>
  );
}
