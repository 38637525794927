import {
  HStack,
  Icon,
  IconButton,
  Link,
  Td,
  Tooltip,
  Tr,
  Wrap,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';

import { PropertyDoc } from '../../common/collections/Properties';
import DocumentLoader from '../../components/DocumentLoader';

export type Props = {
  propertySnap: QueryDocumentSnapshot<PropertyDoc>;
};

export default function PropertyRow({ propertySnap }: Props) {
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'PropertiesScreen.PropertyRow' });

  return (
    <Tr>
      <Td>
        {propertyDoc.name}
      </Td>

      <Td>
        <Wrap>
          {propertyDoc.managerRefs.map((managerRef) => (
            <Suspense fallback={null} key={managerRef.id}>
              <DocumentLoader reference={managerRef}>
                {(managerSnap) => (
                  <Link as={RouterLink} key={managerRef.id} to={`../users/${managerRef.id}`}>
                    {managerSnap.data()?.firstName}
                    {' '}
                    {managerSnap.data()?.lastName}
                  </Link>
                )}
              </DocumentLoader>
            </Suspense>
          ))}
        </Wrap>
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={propertySnap.id}
            />
          </Tooltip>

          <Tooltip label={t('editButton.default')}>
            <IconButton
              aria-label={t('editButton.default')}
              as={RouterLink}
              icon={<Icon as={LuPenSquare} />}
              size="xs"
              to={`${propertySnap.id}/edit`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
