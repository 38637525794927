/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc } from './Customers';
import { TelegramBotDoc } from './TelegramBots';

export type TelegramPaymentTokenDoc = {
  _v: 1;
  createdAt: Timestamp;
  name: string;
  telegramBotRef: DocumentReference<TelegramBotDoc>;
  token: string;
  updatedAt: Timestamp;
};

export const isTelegramPaymentTokenDoc = (
  doc?: DocumentData,
): doc is TelegramPaymentTokenDoc => true;

export const isTelegramPaymentTokenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramPaymentTokenDoc> => ref.parent.id === 'telegramPaymentTokens' && ref.parent.parent?.parent?.id === 'telegramBots';

function isTelegramPaymentTokenSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
TelegramPaymentTokenDoc
>;
function isTelegramPaymentTokenSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
TelegramPaymentTokenDoc
>;
function isTelegramPaymentTokenSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
TelegramPaymentTokenDoc
> | QueryDocumentSnapshot<
TelegramPaymentTokenDoc
> {
  return isTelegramPaymentTokenRef(snap.ref);
}

export { isTelegramPaymentTokenSnap };

export const getTelegramPaymentTokensCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'telegramPaymentTokens') as CollectionReference<TelegramPaymentTokenDoc>;

export const useTelegramPaymentTokensCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getTelegramPaymentTokensCollectionRef(customerRef), [customerRef]);
