import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { TenantDoc } from '../../common/collections/Tenants';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (tenantRef: DocumentReference<TenantDoc>) => void;
  tenantSnap: QueryDocumentSnapshot<TenantDoc>;
};

export default function TenantUpdateForm({
  onComplete,
  tenantSnap,
}: Props) {
  const tenantDoc = useMemo(() => tenantSnap.data(), [tenantSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'TenantUpdateScreen.TenantUpdateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      firstName: yup
        .string()
        .label(t('firstName.label'))
        .required(),
      lastName: yup
        .string()
        .label(t('lastName.label'))
        .required(),
      passport: yup
        .object()
        .label(t('passport.label'))
        .required()
        .shape({
          number: yup
            .string()
            .label(t('passport.number.label'))
            .required(),
        }),
      pesel: yup
        .object()
        .label(t('pesel.label'))
        .required()
        .shape({
          number: yup
            .string()
            .label(t('pesel.number.label')),
        }),
      phoneNumber: yup
        .string()
        .label(t('phoneNumber.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      firstName: tenantDoc.firstName,
      lastName: tenantDoc.lastName,
      passport: tenantDoc.passport,
      pesel: tenantDoc.pesel,
      phoneNumber: tenantDoc.phoneNumber,
    }),
    [tenantDoc],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        await setDoc(
          tenantSnap.ref,
          {
            firstName: values.firstName,
            lastName: values.lastName,
            passport: values.passport,
            pesel: values.pesel,
            phoneNumber: values.phoneNumber,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );

        onComplete(tenantSnap.ref);
      } catch (err) {
        showError(err);
      }
    },
    [onComplete, tenantSnap.ref, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('firstName.label')}
              name="firstName"
            />

            <TextFormControl
              isRequired
              label={t('lastName.label')}
              name="lastName"
            />
          </HStack>

          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('passport.number.label')}
              name="passport.number"
            />

            <TextFormControl
              label={t('pesel.number.label')}
              name="pesel.number"
            />
          </HStack>

          <TextFormControl
            isRequired
            label={t('phoneNumber.label')}
            name="phoneNumber"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            type="submit"
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
