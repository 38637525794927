import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { useInvoicesCollectionRef } from '../../common/collections/Invoices';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import InvoiceRow from './InvoiceRow';

export default function InvoicesTable() {
  const customerSnap = useCustomerSnap();
  const propertySnap = usePropertySnap();

  const invoicesCollectionRef = useInvoicesCollectionRef(customerSnap.ref);
  const { data: invoicesSnap } = useFirestoreCollection(
    query(
      invoicesCollectionRef,
      where('propertyRef', '==', propertySnap.ref),
      orderBy('startsAt', 'desc'),
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'InvoicesScreen' });

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.tenant')}</Th>
              <Th>{t('tableHeader.unit')}</Th>
              <Th>{t('tableHeader.periodAmount')}</Th>
              <Th>{t('tableHeader.overduePenaltyAmount')}</Th>
              <Th>{t('tableHeader.balanceCorrectionAmount')}</Th>
              <Th>{t('tableHeader.period')}</Th>
              <Th>{t('tableHeader.contract')}</Th>
              <Th>{t('tableHeader.status')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {invoicesSnap.docs.map((invoiceSnap) => (
              <InvoiceRow invoiceSnap={invoiceSnap} key={invoiceSnap.id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
