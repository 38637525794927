import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ContractStatus } from '../../common/collections/Contracts';
import { useContractSnap } from '../../components/ContractSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import ContractUpdateForm from './ContractUpdateForm';

export default function ContractUpdateScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const navigate = useNavigate();

  const onComplete = useCallback(
    () => {
      navigate('../', { replace: true });
    },
    [navigate],
  );

  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  const { data: tenantDoc } = useFirestoreDocData(contractDoc.tenantRef);

  if (contractDoc.status !== ContractStatus.DRAFT) {
    return (
      <Navigate replace to=".." />
    );
  }

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{nav('contracts.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">
                    {tenantDoc.firstName}
                    {' '}
                    {tenantDoc.lastName}
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('contracts.update.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {tenantDoc.firstName}
                  {' '}
                  {tenantDoc.lastName}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <ContractUpdateForm contractSnap={contractSnap} onComplete={onComplete} />
      </GridItem>
    </Grid>
  );
}
