import {
  Button,
  Card,
  CardHeader,
  Center,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useContractsCollectionRef } from '../../common/collections/Contracts';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import ContractRow from './ContractRow';

export default function ContractsTable() {
  const customerSnap = useCustomerSnap();
  const propertySnap = usePropertySnap();

  const contractsCollectionRef = useContractsCollectionRef(customerSnap.ref);
  const { data: contractsSnap } = useFirestoreCollection(
    query(
      contractsCollectionRef,
      where('propertyRef', '==', propertySnap.ref),
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'ContractsScreen' });

  if (!contractsSnap.docs.length) {
    return (
      <Center h="100%">
        <Button as={Link} leftIcon={<Icon as={LuPlusSquare} />} to="new">
          {t('createButton.default')}
        </Button>
      </Center>
    );
  }

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.name')}</Th>
              <Th>{t('tableHeader.tenant')}</Th>
              <Th>{t('tableHeader.unit')}</Th>
              <Th>{t('tableHeader.startDate')}</Th>
              <Th>{t('tableHeader.endDate')}</Th>
              <Th>{t('tableHeader.status')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {contractsSnap.docs.map((contractSnap) => (
              <ContractRow contractSnap={contractSnap} key={contractSnap.id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
