import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useTelegramBotsCollectionRef } from '../../common/collections/TelegramBots';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import TelegramBotSnapProvider from '../../components/TelegramBotSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function TelegramBotSnapLayout({ fallback }: Props) {
  const { telegramBotId } = useParams<{ telegramBotId: string }>();

  const customerSnap = useCustomerSnap();

  const telegramBotsCollectionRef = useTelegramBotsCollectionRef(customerSnap.ref);
  const telegramBotRef = useMemo(
    () => doc(telegramBotsCollectionRef, telegramBotId),
    [telegramBotId, telegramBotsCollectionRef],
  );

  const { data: telegramBotSnap } = useFirestoreDoc(telegramBotRef);

  if (!telegramBotSnap.exists()) {
    return fallback;
  }

  return (
    <TelegramBotSnapProvider snap={telegramBotSnap}>
      <Outlet />
    </TelegramBotSnapProvider>
  );
}
