import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'reactfire';
import * as yup from 'yup';

import EmailFormControl from '../../components/EmailFormControl';
import PasswordFormControl from '../../components/PasswordFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: () => void;
};

export default function LoginForm({
  onComplete,
}: Props) {
  const { t } = useTranslation('translations', { keyPrefix: 'LoginScreen.LoginForm' });

  const schema = useMemo(
    () => yup.object().shape({
      email: yup
        .string()
        .label(t('email.label'))
        .required(),
      password: yup
        .string()
        .label(t('password.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      email: '',
      password: '',
    }),
    [],
  );

  const showError = useShowError();

  const auth = useAuth();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password,
        );
        onComplete();
      } catch (err) {
        showError(err);
      }
    },
    [auth, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <Card
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <CardHeader>
            <Heading>
              {t('title')}
            </Heading>
          </CardHeader>

          <CardBody>
            <VStack
              alignItems="stretch"
              spacing={3}
            >
              <EmailFormControl
                label={t('email.label')}
                name="email"
              />

              <PasswordFormControl
                label={t('password.label')}
                name="password"
              />
            </VStack>
          </CardBody>

          <CardFooter>
            <Button
              isLoading={isSubmitting}
              loadingText={t('loginButton.loading')}
              type="submit"
              width="100%"
            >
              {t('loginButton.default')}
            </Button>
          </CardFooter>
        </Card>
      )}
    </Formik>
  );
}
