import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import React, { useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
  options: Record<string, string>;
}

export default function RadioFormControl({
  isRequired = false,
  label,
  name,
  options,
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<string>(name);

  const handleChange = useCallback(
    (value: string) => {
      helper.setValue(value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <RadioGroup onChange={handleChange} value={input.value}>
        <VStack alignItems="stretch">
          {_.toPairs(options).map(([key, value]) => (
            <Radio key={key} value={key}>{value}</Radio>
          ))}
        </VStack>
      </RadioGroup>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
