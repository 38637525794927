import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronDown, LuGlobe } from 'react-icons/lu';

const LANGUAGES = ['EN', 'PL', 'UK', 'RU'];

export default function Languages() {
  const { i18n } = useTranslation();

  const handleClick = useCallback(
    (lang: string | string[]) => {
      i18n.changeLanguage(lang as string).catch(() => {});
    },
    [i18n],
  );

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icon as={LuGlobe} />}
        rightIcon={<Icon as={LuChevronDown} />}
      >
        {i18n.language.toUpperCase()}
      </MenuButton>

      <MenuList>
        <MenuOptionGroup onChange={handleClick} type="radio" value={i18n.language}>
          {LANGUAGES.map((lang: typeof LANGUAGES[number]) => (
            <MenuItemOption key={lang} value={lang.toLowerCase()}>
              {lang.toUpperCase()}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
