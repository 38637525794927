import {
  Box,
  Button,
  Divider,
  Icon,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  LuBot,
  LuHome,
  LuMessagesSquare,
  LuUser,
} from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { UserRole } from '../../common/collections/Users';
import { useCurrentUserRef } from '../CheckAuthLayout';

export default function AdminNav() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const currentUserRef = useCurrentUserRef();
  const { data: currentUserDoc } = useFirestoreDocData(currentUserRef);

  const propertiesMatch = useMatch('/:customerId/admin/properties/*');
  const telegramBotsMatch = useMatch('/:customerId/admin/telegramBots/*');
  const usersMatch = useMatch('/:customerId/admin/users/*');
  const complainsMatch = useMatch('/:customerId/admin/complains/*');

  if (currentUserDoc.role !== UserRole.ADMIN) {
    return null;
  }

  return (
    <VStack alignItems="stretch">

      <Box>
        <Box fontSize="l" fontWeight="bold" mb={2}>
          {nav('administration.title')}
        </Box>

        <Divider />
      </Box>

      <Button
        as={Link}
        isActive={!!propertiesMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuHome} />}
        to="admin/properties"
        variant="ghost"
      >
        {nav('properties.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!usersMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuUser} />}
        to="admin/users"
        variant="ghost"
      >
        {nav('users.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!complainsMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuMessagesSquare} />}
        to="admin/complains"
        variant="ghost"
      >
        {nav('complains.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!telegramBotsMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuBot} />}
        to="admin/telegramBots"
        variant="ghost"
      >
        {nav('telegramBots.title')}
      </Button>
    </VStack>
  );
}
