import {
  Center,
  ChakraProvider,
  extendTheme,
  Spinner,
  ThemeConfig,
} from '@chakra-ui/react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import {
  CACHE_SIZE_UNLIMITED,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import { Suspense } from 'react';
import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  PerformanceProvider,
  RemoteConfigProvider,
  StorageProvider,
} from 'reactfire';
import { ReportHandler } from 'web-vitals';

import './i18n';
import MainRouter from './MainRouter';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
        overscrollBehavior: 'none',
      },
    },
  },
});

const firebaseApp = initializeApp(window.location.hostname === 'app.leezly.com' ? {
  apiKey: 'AIzaSyAyBju0DXDoooCYpvrHtTkh-BgHUEOpL48',
  appId: '1:682631193732:web:18b3dbeac6272927df0ed1',
  authDomain: 'rental-7ce68.firebaseapp.com',
  measurementId: 'G-76YLG7QC4N',
  messagingSenderId: '682631193732',
  projectId: 'rental-7ce68',
  storageBucket: 'rental-7ce68.appspot.com',
} : {
  apiKey: 'AIzaSyCE2pmDCzUBp75taebFCxKeYGMRcbZi2hg',
  appId: '1:670535218823:web:ffa7e1ec73046d34d6ea22',
  authDomain: 'leezly-staging.firebaseapp.com',
  measurementId: 'G-BNS23Y0V49',
  messagingSenderId: '670535218823',
  projectId: 'leezly-staging',
  storageBucket: 'leezly-staging.appspot.com',
});

// initializeAppCheck(firebaseApp, {
//   isTokenAutoRefreshEnabled: true,
//   provider: new ReCaptchaV3Provider('6Ld_ASUqAAAAAAwkG6OCJ_4glcZVg12C6yLQYcTd'),
// });

const remoteConfigInstance = getRemoteConfig(firebaseApp);
remoteConfigInstance.settings.minimumFetchIntervalMillis = 1000 * 60 * 5;

const authInstance = getAuth(firebaseApp);
const analyticsInstance = getAnalytics(firebaseApp);
const functionsInstance = getFunctions(firebaseApp);
const storageInstance = getStorage(firebaseApp);
const performanceInstance = getPerformance(firebaseApp);

const webVitalsReportHandler: ReportHandler = ({ delta, id, name }) => {
  logEvent(analyticsInstance, name, {
    eventAction: name,
    eventCategory: 'Web Vitals',
    eventLabel: id,
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
  });
};

import('web-vitals').then(({
  getCLS, getFCP, getFID, getLCP, getTTFB,
}) => {
  getCLS(webVitalsReportHandler);
  getFID(webVitalsReportHandler);
  getFCP(webVitalsReportHandler);
  getLCP(webVitalsReportHandler);
  getTTFB(webVitalsReportHandler);
}).catch(() => { /* do nothing */ });

const firestoreInstance = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    tabManager: persistentMultipleTabManager(),
  }),
});

export default function Main() {
  return (
    <ChakraProvider theme={theme}>
      <FirebaseAppProvider firebaseApp={firebaseApp} suspense>
        <AuthProvider sdk={authInstance}>
          <AnalyticsProvider sdk={analyticsInstance}>
            <FirestoreProvider sdk={firestoreInstance}>
              <FunctionsProvider sdk={functionsInstance}>
                <StorageProvider sdk={storageInstance}>
                  <RemoteConfigProvider sdk={remoteConfigInstance}>
                    <PerformanceProvider sdk={performanceInstance}>
                      <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
                        <MainRouter />
                      </Suspense>
                    </PerformanceProvider>
                  </RemoteConfigProvider>
                </StorageProvider>
              </FunctionsProvider>
            </FirestoreProvider>
          </AnalyticsProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </ChakraProvider>
  );
}
