/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc } from './Customers';

export type TelegramBotDoc = {
  _v: 1;
  createdAt: Timestamp;
  description: string;
  name: string;
  shortDescription: string;
  token: string;
  updatedAt: Timestamp;
  username: string;
};

export const isTelegramBotDoc = (
  doc?: DocumentData,
): doc is TelegramBotDoc => true;

export const isTelegramBotRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramBotDoc> => ref.parent.id === 'telegramBots' && ref.parent.parent?.parent?.id === 'customers';

function isTelegramBotSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
TelegramBotDoc
>;
function isTelegramBotSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
TelegramBotDoc
>;
function isTelegramBotSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
TelegramBotDoc
> | QueryDocumentSnapshot<
TelegramBotDoc
> {
  return isTelegramBotRef(snap.ref);
}

export { isTelegramBotSnap };

export const getTelegramBotsCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'telegramBots') as CollectionReference<TelegramBotDoc>;

export const useTelegramBotsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getTelegramBotsCollectionRef(customerRef), [customerRef]);
