import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import {
  useTelegramPaymentTokensCollectionRef,
} from '../../common/collections/TelegramPaymentTokens';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import TelegramPaymentTokenSnapProvider from '../../components/TelegramPaymentTokenSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function TelegramPaymentTokenSnapLayout({ fallback }: Props) {
  const { telegramPaymentTokenId } = useParams<{ telegramPaymentTokenId: string }>();

  const customerSnap = useCustomerSnap();
  const telegramPaymentTokensCollectionRef = useTelegramPaymentTokensCollectionRef(
    customerSnap.ref,
  );
  const telegramPaymentTokenRef = useMemo(
    () => doc(telegramPaymentTokensCollectionRef, telegramPaymentTokenId),
    [telegramPaymentTokenId, telegramPaymentTokensCollectionRef],
  );

  const { data: telegramPaymentTokenSnap } = useFirestoreDoc(telegramPaymentTokenRef);

  if (!telegramPaymentTokenSnap.exists()) {
    return fallback;
  }

  return (
    <TelegramPaymentTokenSnapProvider snap={telegramPaymentTokenSnap}>
      <Outlet />
    </TelegramPaymentTokenSnapProvider>
  );
}
