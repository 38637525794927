/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { ContractDoc } from './Contracts';
import { CustomerDoc } from './Customers';
import { PropertyDoc } from './Properties';
import { TenantDoc } from './Tenants';
import { UnitDoc } from './Units';

export enum InvoiceStatus {
  /**
   * The invoice has been settled and archived for record-keeping purposes.
   * @deprecated
   */
  ARCHIVED = 'ARCHIVED',

  /**
   * The invoice was cancelled and is no longer valid, often due to errors or
   * changes in the billing process.
   * @deprecated
   */
  CANCELLED = 'CANCELLED',

  /**
   * The tenant has disputed the invoice, and the issue is under review.
   * @deprecated
   */
  DISPUTED = 'DISPUTED',

  /**
   * The invoice is being prepared and has not yet been finalized or sent to the tenant.
   * @deprecated
   */
  DRAFT = 'DRAFT',

  /**
   * An attempt to collect payment for the invoice failed, possibly
   * due to issues with the payment method.
   * @deprecated
   */
  FAILED = 'FAILED',

  /**
   * The payment due date has passed, and the invoice has not been fully paid.
   */
  OVERDUE = 'OVERDUE',

  /**
   * The invoice has been fully paid by the tenant.
   */
  PAID = 'PAID',

  /**
   * The invoice has been finalized and issued, but payment is not yet due or has not been made.
   */
  PENDING = 'PENDING',

  /**
   * The payment for the invoice was refunded to the tenant, either partially or in full.
   * @deprecated
   */
  REFUNDED = 'REFUNDED',

  /**
   * The invoice has been sent to the tenant and is awaiting payment.
   * @deprecated
   */
  SENT = 'SENT',

  /**
   * The invoice has been written off as a loss, usually when it is deemed uncollectible.
   * @deprecated
   */
  WRITTEN_OFF = 'WRITTEN_OFF',
}

export type InvoiceDoc = {
  _v: 1;
  balanceCorrectionAmount: number;
  contractRef: DocumentReference<ContractDoc>;
  createdAt: Timestamp;
  endsAt: Timestamp;
  orderInfo?: {
    email?: string;
    name?: string;
    phone_number?: string;
    shipping_address?: {
      city: string;
      country_code: string;
      post_code: string;
      state: string;
      street_line1: string;
      street_line2: string;
    };
  };
  overdueDays: number;
  overduePenaltyAmount: number;
  periodAmount: number;
  propertyRef: DocumentReference<PropertyDoc>;
  providerPaymentChargeId?: string;
  startsAt: Timestamp;
  status: InvoiceStatus;
  telegramPaymentChargeId?: string;
  tenantRef: DocumentReference<TenantDoc>;
  unitRef: DocumentReference<UnitDoc>;
  updatedAt: Timestamp;
};

export const isInvoiceDoc = (
  doc?: DocumentData,
): doc is InvoiceDoc => true;

export const isInvoiceRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InvoiceDoc> => ref.parent.id === 'invoices' && ref.parent.parent?.parent?.id === 'customers';

function isInvoiceSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
InvoiceDoc
>;
function isInvoiceSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
InvoiceDoc
>;
function isInvoiceSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
InvoiceDoc
> | QueryDocumentSnapshot<
InvoiceDoc
> {
  return isInvoiceRef(snap.ref);
}

export { isInvoiceSnap };

export const getInvoicesCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'invoices') as CollectionReference<InvoiceDoc>;

export const useInvoicesCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getInvoicesCollectionRef(customerRef), [customerRef]);
