import {
  HStack,
  Icon,
  IconButton,
  Link,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ComplainDoc } from '../../common/collections/Complains';

export type Props = {
  complainSnap: QueryDocumentSnapshot<ComplainDoc>;
};

export default function ComplainRow({ complainSnap }: Props) {
  const complainDoc = useMemo(() => complainSnap.data(), [complainSnap]);

  const { data: propertyDoc } = useFirestoreDocData(complainDoc.propertyRef);
  const { data: tenantDoc } = useFirestoreDocData(complainDoc.tenantRef);

  const { t } = useTranslation('translations', { keyPrefix: 'ComplainsScreen.ComplainRow' });

  return (
    <Tr>
      <Td>
        <Link as={RouterLink} to={`../../properties/${complainDoc.propertyRef.id}/tenants/${complainDoc.tenantRef.id}`}>
          {tenantDoc.firstName}
          {' '}
          {tenantDoc.lastName}
        </Link>
      </Td>

      <Td>
        <Link as={RouterLink} to={`../properties/${complainDoc.propertyRef.id}`}>
          {propertyDoc.name}
        </Link>
      </Td>

      <Td>
        {t('createdAt', {
          createdAt: complainDoc.createdAt.toDate(),
        })}
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={complainSnap.id}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
