import { QueryDocumentSnapshot } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

import { TenantDoc } from '../common/collections/Tenants';

export type Props = {
  snap: QueryDocumentSnapshot<TenantDoc>;
};

const Context = createContext<null | QueryDocumentSnapshot<TenantDoc>>(null);

export const useTenantSnap = () => {
  const contractSnap = useContext(Context);

  if (!contractSnap) {
    throw new Error('TenantSnapProvider');
  }

  return contractSnap;
};

export default function TenantSnapProvider({
  children,
  snap,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={snap}>
      {children}
    </Context.Provider>
  );
}
