import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useFirestoreCollection } from 'reactfire';

import { useComplainsCollectionRef } from '../../common/collections/Complains';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import ComplainRow from './ComplainRow';

export default function ComplainsScreen() {
  const customerSnap = useCustomerSnap();
  const complainsCollectionRef = useComplainsCollectionRef(customerSnap.ref);

  const { data: complainsSnap } = useFirestoreCollection(
    query(
      complainsCollectionRef,
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'ComplainsScreen' });

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.tenant')}</Th>
              <Th>{t('tableHeader.property')}</Th>
              <Th>{t('tableHeader.createdAt')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {complainsSnap.docs.map((complainSnap) => (
              <ComplainRow complainSnap={complainSnap} key={complainSnap.id} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
