import {
  HStack,
  Icon,
  IconButton,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import { TelegramPaymentTokenDoc } from '../../common/collections/TelegramPaymentTokens';

export type Props = {
  telegramPaymentTokenSnap: QueryDocumentSnapshot<TelegramPaymentTokenDoc>;
};

export default function TelegramPaymentTokenRow({ telegramPaymentTokenSnap }: Props) {
  const telegramPaymentTokenDoc = useMemo(
    () => telegramPaymentTokenSnap.data(),
    [telegramPaymentTokenSnap],
  );

  const { t } = useTranslation('translations', { keyPrefix: 'TelegramPaymentTokensScreen.TelegramPaymentTokenRow' });

  return (
    <Tr>
      <Td>
        {telegramPaymentTokenDoc.name}
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={Link}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={telegramPaymentTokenSnap.id}
            />
          </Tooltip>

          <Tooltip label={t('editButton.default')}>
            <IconButton
              aria-label={t('editButton.default')}
              as={Link}
              icon={<Icon as={LuPenSquare} />}
              size="xs"
              to={`${telegramPaymentTokenSnap.id}/edit`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
