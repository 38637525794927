import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { useInvoiceSnap } from '../../components/InvoiceSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';

export default function InvoiceScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const invoiceSnap = useInvoiceSnap();
  const invoiceDoc = useMemo(() => invoiceSnap.data(), [invoiceSnap]);

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  const { data: contractDoc } = useFirestoreDocData(invoiceDoc.contractRef);
  const { data: tenantDoc } = useFirestoreDocData(contractDoc.tenantRef);

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('invoices.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">
                    {tenantDoc.firstName}
                    {' '}
                    {tenantDoc.lastName}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {tenantDoc.firstName}
                  {' '}
                  {tenantDoc.lastName}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto" />
    </Grid>
  );
}
