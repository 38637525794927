import {
  Badge,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { InvoiceDoc } from '../../common/collections/Invoices';
import useInvoiceStatusColorScheme from '../../hooks/useInvoiceStatusColorScheme';
import ContractCell from './ContractCell';
import TenantCell from './TenantCell';
import UnitCell from './UnitCell';

export type Props = {
  invoiceSnap: QueryDocumentSnapshot<InvoiceDoc>;
};

export default function InvoiceRow({ invoiceSnap }: Props) {
  const invoiceDoc = useMemo(() => invoiceSnap.data(), [invoiceSnap]);

  const { data: contractDoc } = useFirestoreDocData(invoiceDoc.contractRef);

  const { t } = useTranslation('translations', { keyPrefix: 'InvoicesScreen.InvoiceRow' });

  const statusColorScheme = useInvoiceStatusColorScheme(invoiceDoc.status);

  return (
    <Tr>
      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <TenantCell tenantRef={contractDoc.tenantRef} />
        </Suspense>
      </Td>

      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <UnitCell unitRef={contractDoc.unitRef} />
        </Suspense>
      </Td>

      <Td opacity={invoiceDoc.periodAmount === 0 ? 0.5 : 1}>
        {t('periodAmount', {
          formatParams: {
            periodAmount: { currency: contractDoc.currency },
          },
          periodAmount: invoiceDoc.periodAmount / 100,
        })}
      </Td>

      <Td opacity={invoiceDoc.overduePenaltyAmount === 0 ? 0.5 : 1}>
        {t('overduePenaltyAmount', {
          formatParams: {
            overduePenaltyAmount: { currency: contractDoc.currency },
          },
          overduePenaltyAmount: invoiceDoc.overduePenaltyAmount / 100,
        })}
      </Td>

      <Td opacity={invoiceDoc.balanceCorrectionAmount === 0 ? 0.5 : 1}>
        {t('balanceCorrectionAmount', {
          balanceCorrectionAmount: invoiceDoc.balanceCorrectionAmount / 100,
          formatParams: {
            balanceCorrectionAmount: { currency: contractDoc.currency },
          },
        })}
      </Td>

      <Td>
        {t('period', {
          endDate: invoiceDoc.endsAt.toDate(),
          startDate: invoiceDoc.startsAt.toDate(),
        })}
      </Td>

      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <ContractCell contractRef={invoiceDoc.contractRef} />
        </Suspense>
      </Td>

      <Td>
        <Badge colorScheme={statusColorScheme}>
          {invoiceDoc.status}
        </Badge>
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={invoiceSnap.id}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
