import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPenSquare } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';

import { ContractStatus } from '../../common/collections/Contracts';
import { useContractSnap } from '../../components/ContractSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import ContractDatesCell from './ContractDatesCell';
import ContractMoneyCell from './ContractMoneyCell';
import FinesButton from './FinesButton';
import FinesCell from './FinesCell';
import InvoicesCell from './InvoicesCell';
import StatusButton from './StatusButton';
import TenantCell from './TenantCell';

export default function ContractScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });
  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen' });

  const navigate = useNavigate();

  const onEditClick = useCallback(
    () => {
      navigate('edit');
    },
    [navigate],
  );

  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  return (
    <Grid gap={4} h="100%" templateColumns="repeat(3, 1fr)" templateRows="auto auto 1fr">
      <GridItem colSpan={3} minW={0}>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('contracts.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">
                    {contractDoc.name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {contractDoc.name}
                </Heading>

                <FinesButton />

                <StatusButton />

                {contractDoc.status === ContractStatus.DRAFT ? (
                  <Tooltip label={t('editButton.default')}>
                    <IconButton
                      aria-label={t('editButton.default')}
                      icon={<Icon as={LuPenSquare} />}
                      onClick={onEditClick}
                    />
                  </Tooltip>
                ) : null}
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem minW={0}>
        <ContractMoneyCell />
      </GridItem>

      <GridItem minW={0}>
        <ContractDatesCell />
      </GridItem>

      <GridItem minW={0}>
        <TenantCell />
      </GridItem>

      <GridItem minW={0}>
        <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
          <FinesCell />
        </Suspense>
      </GridItem>

      <GridItem colSpan={2} minW={0}>
        <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
          <InvoicesCell />
        </Suspense>
      </GridItem>
    </Grid>
  );
}
