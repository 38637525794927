import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { setDoc } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuChevronDown } from 'react-icons/lu';

import { ContractStatus } from '../../common/collections/Contracts';
import { useContractSnap } from '../../components/ContractSnapProvider';
import useContractStatusColorScheme from '../../hooks/useContractStatusColorScheme';
import useShowError from '../../hooks/useShowError';

export default function StatusButton() {
  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const statusColorScheme = useContractStatusColorScheme(contractDoc.status);

  const showError = useShowError();

  const handleContractStatus = useCallback(
    (status: ContractStatus) => {
      setDoc(
        contractSnap.ref,
        { status },
        { merge: true },
      ).catch(showError);
    },
    [contractSnap.ref, showError],
  );

  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.StatusButton' });

  switch (contractDoc.status) {
    case ContractStatus.DRAFT: {
      return (
        <Menu>
          <MenuButton
            as={Button}
            colorScheme={statusColorScheme}
            rightIcon={<Icon as={LuChevronDown} />}
          >
            {contractDoc.status}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleContractStatus(ContractStatus.AWAITING_SIGNATURE)}>
              {t('sendForSignatureButton.default')}
            </MenuItem>

            <MenuItem onClick={() => handleContractStatus(ContractStatus.CANCELLED)}>
              {t('cancelButton.default')}
            </MenuItem>
          </MenuList>
        </Menu>
      );
    }
    case ContractStatus.AWAITING_SIGNATURE:
    case ContractStatus.AWAITING_PAYMENT: {
      return (
        <Menu>
          <MenuButton
            as={Button}
            colorScheme={statusColorScheme}
            rightIcon={<Icon as={LuChevronDown} />}
          >
            {contractDoc.status}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleContractStatus(ContractStatus.CANCELLED)}>
              {t('cancelButton.default')}
            </MenuItem>
          </MenuList>
        </Menu>
      );
    }
    case ContractStatus.ACTIVE:
    case ContractStatus.SUSPENDED: {
      return (
        <Menu>
          <MenuButton
            as={Button}
            colorScheme={statusColorScheme}
            rightIcon={<Icon as={LuChevronDown} />}
          >
            {contractDoc.status}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => handleContractStatus(ContractStatus.TERMINATED)}>
              {t('terminateButton.default')}
            </MenuItem>
          </MenuList>
        </Menu>
      );
    }
    default: {
      return (
        <Button colorScheme={statusColorScheme}>
          {contractDoc.status}
        </Button>
      );
    }
  }
}
