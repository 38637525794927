import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';

import { usePropertySnap } from '../../components/PropertySnapProvider';
import ContractsTable from './ContractsTable';

export default function ContractsScreen() {
  const { t } = useTranslation('translations', { keyPrefix: 'ContractsScreen' });
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const propertySnap = usePropertySnap();
  const propertyDoc = useMemo(() => propertySnap.data(), [propertySnap]);

  return (
    <Grid gap={4} h="100%" templateRows="auto 1fr">
      <GridItem>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="../..">{nav('properties.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{propertyDoc.name}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">{nav('contracts.title')}</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {t('title')}
                </Heading>

                <Tooltip label={t('createButton.default')}>
                  <IconButton
                    aria-label={t('createButton.default')}
                    as={Link}
                    icon={<Icon as={LuPlusSquare} />}
                    to="new"
                  />
                </Tooltip>
              </HStack>

            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem overflow="auto">
        <Suspense fallback={<Center h="100%"><Spinner /></Center>}>
          <ContractsTable />
        </Suspense>
      </GridItem>
    </Grid>
  );
}
