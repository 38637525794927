import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback } from 'react';

import useShowError from '../hooks/useShowError';

export type Props = {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
} & InputProps;

export default function TextFormControl({
  isRequired = false,
  label,
  name,
  ...inputProps
}: Props) {
  const showError = useShowError();

  const [input, meta, helper] = useField<string>(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      helper.setValue(e.target.value)
        .catch(showError);
    },
    [helper, showError],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <Input
        onChange={handleChange}
        value={input.value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
      />

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
