import {
  Box,
  Card,
  CardBody,
  Center,
  Grid,
  GridItem,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import AdminNav from './AdminNav';
import ManagerNav from './ManagerNav';
import PropertySelector from './PropertySelector';
import Settings from './Settings';

export default function MainLayout() {
  const params = useParams<{ propertyId?: string }>();
  const [propertyId, setPropertyId] = useState<string | undefined>(
    params.propertyId,
  );

  useEffect(() => {
    if (params.propertyId) {
      setPropertyId(params.propertyId);
    }
  }, [params.propertyId]);

  return (
    <Grid gap={4} h="100%" p={4} templateColumns="320px 1fr">
      <GridItem>
        <Card h="100%">
          <CardBody p={4}>
            <VStack alignItems="stretch" height="100%" spacing={4}>
              <PropertySelector
                onChangePropertyId={setPropertyId}
                propertyId={propertyId}
              />

              {propertyId ? (
                <ManagerNav propertyId={propertyId} />
              ) : (
                <Box flex={1} />
              )}

              <AdminNav />

              <Settings />
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem minH="100%">
        <Suspense
          fallback={(
            <Center h="100%">
              <Spinner />
            </Center>
          )}
        >
          <Outlet />
        </Suspense>
      </GridItem>
    </Grid>
  );
}
