import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useUsersCollectionRef } from '../../common/collections/Users';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import UserSnapProvider from '../../components/UserSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function UserSnapLayout({ fallback }: Props) {
  const { userId } = useParams<{ userId: string }>();

  const customerSnap = useCustomerSnap();

  const usersCollectionRef = useUsersCollectionRef(customerSnap.ref);
  const userRef = useMemo(
    () => doc(usersCollectionRef, userId),
    [userId, usersCollectionRef],
  );

  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    return fallback;
  }

  return (
    <UserSnapProvider snap={userSnap}>
      <Outlet />
    </UserSnapProvider>
  );
}
