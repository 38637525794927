import { Td, Tr } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnouncementDoc } from '../../common/collections/Announcements';

export type Props = {
  announcementSnap: QueryDocumentSnapshot<AnnouncementDoc>;
};

export default function AnnouncementRow({ announcementSnap }: Props) {
  const announcementDoc = useMemo(() => announcementSnap.data(), [announcementSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'AnnouncementsScreen.AnnouncementRow' });

  return (
    <Tr>
      <Td>
        {announcementDoc.message}
      </Td>

      <Td isNumeric>
        {t('createdAt', {
          createdAt: announcementDoc.createdAt.toDate(),
        })}
      </Td>
    </Tr>
  );
}
