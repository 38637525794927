import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { UnitDoc } from '../../common/collections/Units';
import BooleanFormControl from '../../components/BooleanFormControl';
import MonetaryFormControl from '../../components/MonetaryFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import TextFormControl from '../../components/TextFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (unitRef: DocumentReference<UnitDoc>) => void;
  unitSnap: QueryDocumentSnapshot<UnitDoc>;
};

export default function UnitUpdateForm({
  onComplete,
  unitSnap,
}: Props) {
  const unitDoc = useMemo(() => unitSnap.data(), [unitSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'UnitUpdateScreen.UnitUpdateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      deposit: yup
        .number()
        .label(t('deposit.label'))
        .required(),
      isActive: yup
        .boolean()
        .label(t('isActive.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      overdueDailyPenaltyPercent: yup
        .number()
        .label(t('overdueDailyPenaltyPercent.label'))
        .required(),
      periodAmount: yup
        .number()
        .label(t('periodAmount.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      deposit: unitDoc.deposit,
      isActive: unitDoc.isActive,
      name: unitDoc.name,
      overdueDailyPenaltyPercent: unitDoc.overdueDailyPenaltyPercent,
      periodAmount: unitDoc.periodAmount,
    }),
    [unitDoc],
  );

  const showError = useShowError();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        await setDoc(
          unitSnap.ref,
          {
            deposit: values.deposit,
            isActive: values.isActive,
            name: values.name,
            overdueDailyPenaltyPercent: values.overdueDailyPenaltyPercent,
            periodAmount: values.periodAmount,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );

        onComplete(unitSnap.ref);
      } catch (err) {
        showError(err);
      }
    },
    [unitSnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextFormControl
            isRequired
            label={t('name.label')}
            name="name"
          />

          <HStack gap={4}>
            <MonetaryFormControl
              isRequired
              label={t('deposit.label')}
              name="deposit"
            />

            <MonetaryFormControl
              isRequired
              label={t('periodAmount.label')}
              name="periodAmount"
            />
          </HStack>

          <NumberFormControl
            isRequired
            label={t('overdueDailyPenaltyPercent.label')}
            max={10}
            min={0}
            name="overdueDailyPenaltyPercent"
            step={1}
          />

          <BooleanFormControl
            label={t('isActive.label')}
            name="isActive"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('updateButton.loading')}
            type="submit"
          >
            {t('updateButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
