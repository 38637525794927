import { doc } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';

import { useContractsCollectionRef } from '../../common/collections/Contracts';
import ContractSnapProvider from '../../components/ContractSnapProvider';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function ContractSnapLayout({ fallback }: Props) {
  const { contractId } = useParams<{ contractId: string }>();

  const customerSnap = useCustomerSnap();

  const contractsCollectionRef = useContractsCollectionRef(customerSnap.ref);
  const contractRef = useMemo(
    () => doc(contractsCollectionRef, contractId),
    [contractId, contractsCollectionRef],
  );

  const { data: contractSnap } = useFirestoreDoc(contractRef);

  if (!contractSnap.exists()) {
    return fallback;
  }

  return (
    <ContractSnapProvider snap={contractSnap}>
      <Outlet />
    </ContractSnapProvider>
  );
}
