import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from '@chakra-ui/react';
import { doc, DocumentReference } from 'firebase/firestore';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useFirestoreCollection } from 'reactfire';

import { UserDoc, useUsersCollectionRef } from '../common/collections/Users';
import useShowError from '../hooks/useShowError';
import { useCustomerSnap } from './CustomerSnapProvider';

export interface Props {
  // eslint-disable-next-line react/require-default-props
  isRequired?: boolean;
  label: string;
  name: string;
}

export default function UsersFormControl({
  isRequired = false,
  label,
  name,
}: Props) {
  const showError = useShowError();
  const customerSnap = useCustomerSnap();
  const usersCollectionRef = useUsersCollectionRef(customerSnap.ref);

  const { data: usersSnap } = useFirestoreCollection(usersCollectionRef);

  const [input, meta, helper] = useField<DocumentReference<UserDoc>[]>(name);

  const handleChange = useCallback(
    (value: string[]) => {
      helper.setValue(value.map((v) => doc(usersCollectionRef, v)))
        .catch(showError);
    },
    [helper, showError, usersCollectionRef],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <CheckboxGroup onChange={handleChange} value={input.value.map((v) => v.id)}>
        <HStack alignItems="stretch" gap={2}>
          {usersSnap.docs.map((userSnap) => (
            <Checkbox key={userSnap.id} value={userSnap.id}>
              {userSnap.data().firstName}
              {' '}
              {userSnap.data().lastName}
            </Checkbox>
          ))}
        </HStack>
      </CheckboxGroup>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
}
