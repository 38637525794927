import { Icon, MenuItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuBadgeHelp } from 'react-icons/lu';

export default function Documentation() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  return (
    <MenuItem
      as="a"
      href="https://leezly.notion.site/Leezly-Property-Management-System-f8fac058d46e4320bbb1986516672f9f"
      icon={<Icon as={LuBadgeHelp} />}
      target="_blank"
    >
      {nav('docs.title')}
    </MenuItem>
  );
}
