import { Container } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import LoginForm from './LoginForm';

export default function LoginScreen() {
  const navigate = useNavigate();

  const customerSnap = useCustomerSnap();

  const onComplete = useCallback(
    () => navigate(`/${customerSnap.id}`),
    [customerSnap.id, navigate],
  );

  return (
    <Container>
      <LoginForm onComplete={onComplete} />
    </Container>
  );
}
