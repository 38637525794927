import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc } from './Customers';

export enum UserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

export type UserDoc = {
  _v: 1;
  createdAt: Timestamp;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: UserRole;
  updatedAt: Timestamp;
};

export const isUserDoc = (
  doc?: DocumentData,
): doc is UserDoc => true;

export const isUserRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserDoc> => ref.parent.id === 'users' && ref.parent.parent?.parent?.id === 'customers';

function isUserSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
UserDoc
>;
function isUserSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
UserDoc
>;
function isUserSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
UserDoc
> | QueryDocumentSnapshot<
UserDoc
> {
  return isUserRef(snap.ref);
}

export { isUserSnap };

export const getUsersCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'users') as CollectionReference<UserDoc>;

export const useUsersCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getUsersCollectionRef(customerRef), [customerRef]);
