import {
  Card,
  Table,
  TableContainer,
  Td,
  Tr,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useContractSnap } from '../../components/ContractSnapProvider';

export default function ContractMoneyCell() {
  const { t } = useTranslation('translations', { keyPrefix: 'ContractScreen.ContractMoneyCell' });

  const contractSnap = useContractSnap();
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  return (
    <Card h="100%">
      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Tr>
            <Td>
              {t('deposit.label')}
            </Td>

            <Td isNumeric>
              {t('deposit.value', {
                deposit: contractDoc.deposit / 100,
                formatParams: {
                  deposit: { currency: contractDoc.currency },
                },
              })}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('balance.label')}
            </Td>

            <Td isNumeric>
              {t('balance.value', {
                balance: contractDoc.balance / 100,
                formatParams: {
                  balance: { currency: contractDoc.currency },
                },
              })}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('overdueDailyPenaltyPercent.label')}
            </Td>

            <Td isNumeric>
              {t('overdueDailyPenaltyPercent.value', {
                overdueDailyPenaltyPercent: contractDoc.overdueDailyPenaltyPercent,
              })}
            </Td>
          </Tr>

          <Tr>
            <Td>
              {t('periodAmount.label')}
            </Td>

            <Td isNumeric>
              {t('periodAmount.value', {
                formatParams: {
                  periodAmount: { currency: contractDoc.currency },
                },
                periodAmount: contractDoc.periodAmount / 100,
              })}
            </Td>
          </Tr>
        </Table>
      </TableContainer>
    </Card>
  );
}
