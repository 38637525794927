import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  ContractDoc,
  ContractStatus,
  useContractsCollectionRef,
} from '../../common/collections/Contracts';
import { TenantDoc } from '../../common/collections/Tenants';
import { UnitDoc } from '../../common/collections/Units';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import DocumentFormControl from '../../components/DocumentFormControl';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import TenantFormControl from '../../components/TenantFormControl';
import TextFormControl from '../../components/TextFormControl';
import UnitFormControl from '../../components/UnitFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (contractRef: DocumentReference<ContractDoc>) => void;
};

export default function ContractCreateForm({
  onComplete,
}: Props) {
  const customerSnap = useCustomerSnap();
  const contractsCollectionRef = useContractsCollectionRef(customerSnap.ref);

  const { t } = useTranslation('translations', { keyPrefix: 'ContractCreateScreen.ContractCreateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      documentStorageRef: yup
        .string()
        .label(t('documentStorageRef.label')),
      endDate: yup
        .string()
        .label(t('endDate.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      startDate: yup
        .string()
        .label(t('startDate.label'))
        .required(),
      tenantRef: yup
        .mixed<DocumentReference<TenantDoc>>()
        .label(t('tenantRef.label'))
        .required(),
      unitRef: yup
        .mixed<DocumentReference<UnitDoc>>()
        .label(t('unitRef.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      documentStorageRef: '',
      endDate: moment().startOf('day').add(1, 'year').format('YYYY-MM-DD'),
      name: '',
      startDate: moment().startOf('day').format('YYYY-MM-DD'),
      tenantRef: undefined as unknown as DocumentReference<TenantDoc>,
      unitRef: undefined as unknown as DocumentReference<UnitDoc>,
    }),
    [],
  );

  const showError = useShowError();

  const propertySnap = usePropertySnap();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        const contractRef = doc(contractsCollectionRef);

        if (
          !values.tenantRef
          || !values.unitRef
        ) {
          return;
        }

        const selectedUnitSnap = await getDoc(values.unitRef);

        if (!selectedUnitSnap.exists()) {
          return;
        }

        const selectedUnitDoc = selectedUnitSnap.data();

        const selectedPropertySnap = await getDoc(selectedUnitDoc.propertyRef);

        if (!selectedPropertySnap.exists()) {
          return;
        }

        const selectedPropertyDoc = selectedPropertySnap.data();

        await setDoc(
          contractRef,
          {
            _v: 1,
            balance: 0,
            createdAt: serverTimestamp(),
            currency: selectedPropertyDoc.currency,
            deposit: selectedUnitDoc.deposit,
            documentStorageRef: values.documentStorageRef,
            endDate: values.endDate,
            fines: selectedPropertyDoc.fines,
            name: values.name,
            overdueDailyPenaltyPercent: selectedUnitDoc.overdueDailyPenaltyPercent,
            paymentDateShift: selectedPropertyDoc.paymentDateShift ?? 0,
            periodAmount: selectedUnitDoc.periodAmount,
            propertyRef: propertySnap.ref,
            startDate: values.startDate,
            status: ContractStatus.DRAFT,
            tenantRef: values.tenantRef,
            timezone: selectedPropertyDoc.timezone,
            unitRef: values.unitRef,
            updatedAt: serverTimestamp(),
          },
        );

        onComplete(contractRef);
      } catch (err) {
        showError(err);
      }
    },
    [contractsCollectionRef, propertySnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextFormControl
            isRequired
            label={t('name.label')}
            name="name"
          />

          <HStack gap={4}>
            <Suspense fallback={null}>
              <UnitFormControl
                isRequired
                label={t('unitRef.label')}
                name="unitRef"
                propertyRef={propertySnap.ref}
              />
            </Suspense>

            <Suspense fallback={null}>
              <TenantFormControl
                isRequired
                label={t('tenantRef.label')}
                name="tenantRef"
                propertyRef={propertySnap.ref}
              />
            </Suspense>
          </HStack>

          <HStack gap={4}>
            <TextFormControl
              isRequired
              label={t('startDate.label')}
              name="startDate"
              type="date"
            />

            <TextFormControl
              isRequired
              label={t('endDate.label')}
              name="endDate"
              type="date"
            />
          </HStack>

          <DocumentFormControl
            label={t('documentStorageRef.label')}
            name="documentStorageRef"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            type="submit"
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
