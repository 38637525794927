import { Link } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { UnitDoc } from '../../common/collections/Units';

export type Props = {
  unitRef: DocumentReference<UnitDoc>;
};

export default function InvoiceRow({ unitRef }: Props) {
  const { data: unitDoc } = useFirestoreDocData(unitRef);

  return (
    <Link as={RouterLink} to={`../units/${unitRef.id}`}>
      {unitDoc.name}
    </Link>
  );
}
