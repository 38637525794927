import {
  Box,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  SimpleGrid,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPlus, LuTrash } from 'react-icons/lu';

import { PropertyFine } from '../common/collections/Properties';
import useShowError from '../hooks/useShowError';
import MonetaryFormControl from './MonetaryFormControl';
import TextFormControl from './TextFormControl';

export interface Props {
  amountLabel: string;
  label: string;
  name: string;
  nameLabel: string;
}

export default function PropertyFinesFormControl({
  amountLabel,
  label,
  name,
  nameLabel,
}: Props) {
  const { t } = useTranslation('translations', { keyPrefix: 'PropertyUpdateScreen.PropertyUpdateForm' });

  const showError = useShowError();

  const [input, , helper] = useField<PropertyFine[]>(name);

  const handleAddClick = useCallback(
    () => {
      helper.setValue([...input.value, { amount: 0, name: '' }])
        .catch(showError);
    },
    [helper, input.value, showError],
  );

  const handleRemoveClick = useCallback(
    (index: number) => {
      helper.setValue(_.remove(input.value, (v, k) => k !== index))
        .catch(showError);
    },
    [helper, input.value, showError],
  );

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      <SimpleGrid
        justifyContent="start"
        spacing={4}
        templateColumns="repeat(auto-fill, 350px)"
      >
        {input.value.map((v, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Card h="120px" key={i} w="350px">
            <CardBody>
              <HStack alignItems="flex-start" gap={2}>
                <TextFormControl label={nameLabel} name={`${name}.${i}.name`} />

                <Box>
                  <MonetaryFormControl label={amountLabel} name={`${name}.${i}.amount`} />
                </Box>

                <Box pt={8}>
                  <IconButton
                    aria-label="Remove"
                    flexShrink={0}
                    icon={<Icon as={LuTrash} />}
                    onClick={() => handleRemoveClick(i)}
                  />
                </Box>
              </HStack>
            </CardBody>
          </Card>
        ))}

        <Card alignItems="center" as="button" border="1px solid" borderColor="gray.50" borderStyle="dashed" h="120px" justifyContent="center" onClick={handleAddClick} type="button" w="350px">
          <Icon as={LuPlus} boxSize={6} />

          {t('fines.add.default')}
        </Card>
      </SimpleGrid>
    </FormControl>
  );
}
