import { Link, Wrap } from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { useContractsCollectionRef } from '../../common/collections/Contracts';
import { TenantDoc } from '../../common/collections/Tenants';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';

export type Props = {
  tenantSnap: QueryDocumentSnapshot<TenantDoc>;
};

export default function ContractCell({ tenantSnap }: Props) {
  const customerSnap = useCustomerSnap();

  const contractsCollectionRef = useContractsCollectionRef(customerSnap.ref);
  const { data: contractsSnap } = useFirestoreCollection(query(
    contractsCollectionRef,
    where('tenantRef', '==', tenantSnap.ref),
    orderBy('startDate', 'desc'),
    limit(1),
  ));

  return (
    <Wrap>
      {contractsSnap.docs.map((contractSnap) => (
        <Link as={RouterLink} key={contractSnap.id} to={`../contracts/${contractSnap.id}`}>
          {contractSnap.data().name}
        </Link>
      ))}
    </Wrap>
  );
}
