import { Icon, MenuItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LuMail } from 'react-icons/lu';

export default function Help() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  return (
    <MenuItem
      as="a"
      href="mailto:admin@leezly.com"
      icon={<Icon as={LuMail} />}
      target="_blank"
    >
      {nav('help.title')}
    </MenuItem>
  );
}
