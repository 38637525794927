import { Button, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  AnnouncementDoc,
  useAnnouncementsCollectionRef,
} from '../../common/collections/Announcements';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { usePropertySnap } from '../../components/PropertySnapProvider';
import TextareaFormControl from '../../components/TextareaFormControl';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (announcementRef: DocumentReference<AnnouncementDoc>) => void;
};

export default function AnnouncementCreateForm({
  onComplete,
}: Props) {
  const customerSnap = useCustomerSnap();
  const announcementsCollectionRef = useAnnouncementsCollectionRef(customerSnap.ref);

  const { t } = useTranslation('translations', { keyPrefix: 'AnnouncementCreateScreen.AnnouncementCreateForm' });

  const schema = useMemo(
    () => yup.object().shape({
      message: yup
        .string()
        .label(t('message.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      message: '',
    }),
    [],
  );

  const showError = useShowError();

  const propertySnap = usePropertySnap();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      try {
        const announcementRef = doc(announcementsCollectionRef);

        await setDoc(
          announcementRef,
          {
            _v: 1,
            createdAt: Timestamp.now(),
            message: values.message,
            propertyRef: propertySnap.ref,
            updatedAt: Timestamp.now(),
          },
        );

        onComplete(announcementRef);
      } catch (err) {
        showError(err);
      }
    },
    [announcementsCollectionRef, propertySnap.ref, onComplete, showError],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
          spacing={3}
        >
          <TextareaFormControl
            isRequired
            label={t('message.label')}
            name="message"
          />

          <Button
            isLoading={isSubmitting}
            loadingText={t('createButton.loading')}
            type="submit"
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
