import { Button, Icon, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  LuBackpack,
  LuBanknote,
  LuBed,
  LuClipboardSignature,
  LuMegaphone,
} from 'react-icons/lu';
import { Link, useMatch } from 'react-router-dom';

export type Props = {
  propertyId: string;
};

export default function ManagerNav({ propertyId }: Props) {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const unitsMatch = useMatch(`/:customerId/properties/${propertyId}/units/*`);
  const tenantsMatch = useMatch(`/:customerId/properties/${propertyId}/tenants/*`);
  const invoicesMatch = useMatch(`/:customerId/properties/${propertyId}/invoices/*`);
  const contractsMatch = useMatch(`/:customerId/properties/${propertyId}/contracts/*`);
  const announcementsMatch = useMatch(`/:customerId/properties/${propertyId}/announcements/*`);

  return (
    <VStack alignItems="stretch" flex={1}>
      <Button
        as={Link}
        isActive={!!contractsMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuClipboardSignature} />}
        to={`./properties/${propertyId}/contracts`}
        variant="ghost"
      >
        {nav('contracts.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!invoicesMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuBanknote} />}
        to={`./properties/${propertyId}/invoices`}
        variant="ghost"
      >
        {nav('invoices.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!tenantsMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuBackpack} />}
        to={`./properties/${propertyId}/tenants`}
        variant="ghost"
      >
        {nav('tenants.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!unitsMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuBed} />}
        to={`./properties/${propertyId}/units`}
        variant="ghost"
      >
        {nav('units.title')}
      </Button>

      <Button
        as={Link}
        isActive={!!announcementsMatch}
        justifyContent="start"
        leftIcon={<Icon as={LuMegaphone} />}
        to={`./properties/${propertyId}/announcements`}
        variant="ghost"
      >
        {nav('announcements.title')}
      </Button>
    </VStack>
  );
}
