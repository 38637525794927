/* eslint-disable import/no-cycle */
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc } from './Customers';
import { PropertyDoc } from './Properties';

export type Passport = {
  number: string;
};

export type Pesel = {
  number?: string;
};

export type TenantDoc = {
  _v: 1;
  createdAt: Timestamp;
  firstName: string;
  lastName: string;
  passport: Passport;
  pesel: Pesel;
  phoneNumber: string;
  privacyPolicyAcceptedAt?: Timestamp;
  propertyRef?: DocumentReference<PropertyDoc>;
  updatedAt: Timestamp;
};

export const isTenantDoc = (
  doc?: DocumentData,
): doc is TenantDoc => true;

export const isTenantRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TenantDoc> => ref.parent.id === 'tenants' && ref.parent.parent?.parent?.id === 'customers';

function isTenantSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
TenantDoc
>;
function isTenantSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
TenantDoc
>;
function isTenantSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
TenantDoc
> | QueryDocumentSnapshot<
TenantDoc
> {
  return isTenantRef(snap.ref);
}

export { isTenantSnap };

export const getTenantsCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'tenants') as CollectionReference<TenantDoc>;

export const useTenantsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getTenantsCollectionRef(customerRef), [customerRef]);
