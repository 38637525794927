import { doc } from 'firebase/firestore';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAuth, useFirestoreDoc } from 'reactfire';

import { useCustomersCollectionRef } from '../../common/collections/Customers';
import CustomerSnapProvider from '../../components/CustomerSnapProvider';

export type Props = {
  fallback: React.ReactElement;
};

export default function CustomerSnapLayout({ fallback }: Props) {
  const { customerId } = useParams<{ customerId: string }>();

  const customersCollectionRef = useCustomersCollectionRef();
  const customerRef = useMemo(
    () => doc(customersCollectionRef, customerId),
    [customerId, customersCollectionRef],
  );

  const { data: customerSnap } = useFirestoreDoc(customerRef);

  const authInstance = useAuth();

  useEffect(
    () => {
      authInstance.tenantId = customerSnap.data()?.authTenantId ?? null;
    },
    [authInstance, customerSnap],
  );

  if (!customerSnap.exists()) {
    return fallback;
  }

  return (
    <CustomerSnapProvider snap={customerSnap}>
      <Outlet />
    </CustomerSnapProvider>
  );
}
