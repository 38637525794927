import {
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuEye, LuPenSquare } from 'react-icons/lu';
import { Link as RouterLink } from 'react-router-dom';

import { TenantDoc } from '../../common/collections/Tenants';
import ContractCell from './ContractCell';
import UnitCell from './UnitCell';

export type Props = {
  tenantSnap: QueryDocumentSnapshot<TenantDoc>;
};

export default function TenantRow({ tenantSnap }: Props) {
  const tenantDoc = useMemo(() => tenantSnap.data(), [tenantSnap]);

  const { t } = useTranslation('translations', { keyPrefix: 'TenantsScreen.TenantRow' });

  return (
    <Tr>
      <Td>
        {tenantDoc.firstName}
        {' '}
        {tenantDoc.lastName}
      </Td>

      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <UnitCell tenantSnap={tenantSnap} />
        </Suspense>
      </Td>

      <Td>
        <Suspense fallback={<Skeleton h={4} w="100px" />}>
          <ContractCell tenantSnap={tenantSnap} />
        </Suspense>
      </Td>

      <Td>
        <HStack>
          <Tooltip label={t('viewButton.default')}>
            <IconButton
              aria-label={t('viewButton.default')}
              as={RouterLink}
              icon={<Icon as={LuEye} />}
              size="xs"
              to={tenantSnap.id}
            />
          </Tooltip>

          <Tooltip label={t('editButton.default')}>
            <IconButton
              aria-label={t('editButton.default')}
              as={RouterLink}
              icon={<Icon as={LuPenSquare} />}
              size="xs"
              to={`${tenantSnap.id}/edit`}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
}
