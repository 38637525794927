import {
  Button,
  Card,
  CardHeader,
  Center,
  Heading,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { query } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LuPlusSquare } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';

import { usePropertiesCollectionRef } from '../../common/collections/Properties';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import PropertyRow from './PropertyRow';

export default function PropertiesTable() {
  const customerSnap = useCustomerSnap();
  const propertiesCollectionRef = usePropertiesCollectionRef(customerSnap.ref);

  const { data: propertiesSnap } = useFirestoreCollection(
    query(
      propertiesCollectionRef,
    ),
  );

  const { t } = useTranslation('translations', { keyPrefix: 'PropertiesScreen' });

  if (!propertiesSnap.docs.length) {
    return (
      <Center h="100%">
        <Button as={Link} leftIcon={<Icon as={LuPlusSquare} />} to="new">
          {t('createButton.default')}
        </Button>
      </Center>
    );
  }

  return (
    <Card h="100%">
      <CardHeader p={4}>
        <Heading fontSize="lg">
          {t('title')}
        </Heading>
      </CardHeader>

      <TableContainer overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>{t('tableHeader.name')}</Th>
              <Th>{t('tableHeader.managers')}</Th>
              <Th w={0} />
            </Tr>
          </Thead>
          <Tbody>
            {propertiesSnap.docs.map((propertySnap) => (
              <PropertyRow key={propertySnap.id} propertySnap={propertySnap} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  );
}
