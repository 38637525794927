import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { useComplainSnap } from '../../components/ComplainSnapProvider';
import PropertyCell from './PropertyCell';
import TenantCell from './TenantCell';

export default function ComplainScreen() {
  const { t: nav } = useTranslation('translations', { keyPrefix: 'Navigation' });

  const complainSnap = useComplainSnap();
  const complainDoc = useMemo(() => complainSnap.data(), [complainSnap]);

  const { data: tenantDoc } = useFirestoreDocData(complainDoc.tenantRef);

  return (
    <Grid gap={4} h="100%" templateColumns="repeat(2, 1fr)" templateRows="auto auto 1fr">
      <GridItem colSpan={2}>
        <Card>
          <CardBody p={4}>
            <VStack alignItems="stretch">
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to="..">{nav('complains.title')}</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to=".">
                    {tenantDoc.firstName}
                    {' '}
                    {tenantDoc.lastName}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>

              <HStack>
                <Heading flex={1} fontSize="3xl" h={10}>
                  {tenantDoc.firstName}
                  {' '}
                  {tenantDoc.lastName}
                </Heading>
              </HStack>
            </VStack>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem>
        <TenantCell />
      </GridItem>

      <GridItem>
        <PropertyCell />
      </GridItem>

      <GridItem colSpan={2} overflow="auto">
        <Card>
          <CardBody>
            {complainDoc.message}
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
}
