import { Link } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFirestoreDocData } from 'reactfire';

import { ContractDoc } from '../../common/collections/Contracts';

export type Props = {
  contractSnap: QueryDocumentSnapshot<ContractDoc>;
};

export default function UnitCell({ contractSnap }: Props) {
  const contractDoc = useMemo(() => contractSnap.data(), [contractSnap]);

  const { data: unitDoc } = useFirestoreDocData(contractDoc.unitRef);

  return (
    <Link as={RouterLink} to={`../units/${contractDoc.unitRef.id}`}>
      {unitDoc.name}
    </Link>
  );
}
