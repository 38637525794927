import { ThemeTypings } from '@chakra-ui/react';
import { useMemo } from 'react';

import { InvoiceStatus } from '../common/collections/Invoices';

const useInvoiceStatusColorScheme = (status: InvoiceStatus): ThemeTypings['colorSchemes'] => useMemo(
  () => {
    switch (status) {
      case InvoiceStatus.DRAFT: {
        return 'gray';
      }
      case InvoiceStatus.PENDING:
      case InvoiceStatus.SENT: {
        return 'yellow';
      }
      case InvoiceStatus.WRITTEN_OFF:
      case InvoiceStatus.OVERDUE:
      case InvoiceStatus.CANCELLED:
      case InvoiceStatus.DISPUTED:
      case InvoiceStatus.FAILED: {
        return 'red';
      }
      case InvoiceStatus.REFUNDED: {
        return 'orange';
      }
      case InvoiceStatus.PAID: {
        return 'green';
      }
      default: {
        return 'teal';
      }
    }
  },
  [status],
);

export default useInvoiceStatusColorScheme;
