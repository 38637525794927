import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { CustomerDoc } from './Customers';
import { PropertyDoc } from './Properties';

export type AnnouncementDoc = {
  _v: 1;
  createdAt: Timestamp;
  message: string;
  propertyRef: DocumentReference<PropertyDoc>;
  updatedAt: Timestamp;
};

export const isAnnouncementDoc = (
  doc?: DocumentData,
): doc is AnnouncementDoc => true;

export const isAnnouncementRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AnnouncementDoc> => ref.parent.id === 'announcements' && ref.parent.parent?.parent?.id === 'customers';

function isAnnouncementSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
AnnouncementDoc
>;
function isAnnouncementSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
AnnouncementDoc
>;
function isAnnouncementSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
AnnouncementDoc
> | QueryDocumentSnapshot<
AnnouncementDoc
> {
  return isAnnouncementRef(snap.ref);
}

export { isAnnouncementSnap };

export const getAnnouncementsCollectionRef = (customerRef: DocumentReference<CustomerDoc>) => collection(customerRef, 'announcements') as CollectionReference<AnnouncementDoc>;

export const useAnnouncementsCollectionRef = (
  customerRef: DocumentReference<CustomerDoc>,
) => useMemo(() => getAnnouncementsCollectionRef(customerRef), [customerRef]);
