import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { LuChevronDown, LuHome } from 'react-icons/lu';
import { useFirestoreCollection, useFirestoreDocData } from 'reactfire';

import { usePropertiesCollectionRef } from '../../common/collections/Properties';
import { UserRole } from '../../common/collections/Users';
import { useCustomerSnap } from '../../components/CustomerSnapProvider';
import { useCurrentUserRef } from '../CheckAuthLayout';

export type Props = {
  onChangePropertyId: (propertyId: string) => unknown;
  propertyId: string | undefined;
};

export default function PropertySelector({ onChangePropertyId, propertyId }: Props) {
  const currentUserRef = useCurrentUserRef();
  const { data: currentUserDoc } = useFirestoreDocData(currentUserRef);

  const customerSnap = useCustomerSnap();
  const propertiesCollectionRef = usePropertiesCollectionRef(customerSnap.ref);
  const propertiesQuery = useMemo(
    () => {
      if (currentUserDoc.role === UserRole.ADMIN) {
        return query(propertiesCollectionRef);
      }

      return query(
        propertiesCollectionRef,
        where('managerRefs', 'array-contains', currentUserRef),
      );
    },
    [currentUserDoc.role, propertiesCollectionRef, currentUserRef],
  );
  const { data: propertiesSnap } = useFirestoreCollection(propertiesQuery);

  const activePropertySnap = useMemo(
    () => _.find(propertiesSnap.docs, (snap) => snap.id === propertyId),
    [propertiesSnap.docs, propertyId],
  );

  const onChange = useCallback(
    (value: string | string[]) => {
      onChangePropertyId(typeof value === 'string' ? value : value[0]);
    },
    [onChangePropertyId],
  );

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icon as={LuHome} />}
        rightIcon={<Icon as={LuChevronDown} />}
      >
        {activePropertySnap ? activePropertySnap.data().name : '-'}
      </MenuButton>

      <MenuList>
        <MenuOptionGroup onChange={onChange} type="radio" value={propertyId}>
          {propertiesSnap.docs.map((propertySnap) => (
            <MenuItemOption key={propertySnap.id} value={propertySnap.id}>
              {propertySnap.data().name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
